import { forwardRef, type Ref, type ReactNode, useEffect, useState, type HTMLAttributes, type ReactElement } from 'react'
import './DateSelector.scss'

// React datepicker
import DatePicker from 'react-datepicker'
import type { Locale } from 'date-fns'

// Localization
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

// Helpers
import { DateTime } from 'luxon'

// Utils
import { localeImports } from '../../../utils/dateFnsLocaleMapping'
import { parseDate } from '../../../utils/dateHelper'
import { generateRandomLetters } from '../../../utils/commonHelper'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'
import Tooltip from '../tooltip/Tooltip'
import Loading from '../loading/Loading'

interface DateSelectorProps {
	icon?: string
	label?: string
	name?: string
	customInput?: ReactElement
	showTimeSelect?: boolean
	size?: string
	valid?: boolean
	invalid?: boolean
	invalidMessage?: string
	warning?: boolean
	className?: string
	locale?: string
	dateFormat?: string
	timeFormat?: string
	timeIntervals?: number
	innerRef?: Ref<DatePicker>
	autoComplete?: string
	selected?: Date | null
	minDate?: Date | null
	maxDate?: Date | null
	onChange?: (date: DateTime | null) => void
	showTooltip?: boolean
	tooltipText?: string
	placeholder?: string
	disabled?: boolean
	selectsMultiple?: never // `selectsMultiple` is disallowed
}

const DateSelector = (props: DateSelectorProps, innerRef: Ref<DatePicker>): ReactNode => {
	// Translation Hook
	const { t } = useTranslation()

	const [loadedLocale, setLoadedLocale] = useState<Locale | null>()

	// Destruct props
	const {
		icon,
		label,
		name = generateRandomLetters(),
		size,
		valid,
		invalid,
		invalidMessage,
		warning,
		className,
		locale = i18next.language,
		dateFormat = 'P',
		selected,
		minDate,
		maxDate,
		onChange = () => {},
		showTooltip = false,
		tooltipText = '',
		placeholder,
		customInput,
		selectsMultiple,
		...rest
	} = props

	useEffect(() => {
		localeImports[locale].then((loc: any) => {
			setLoadedLocale(loc)
		})
	}, [locale])

	// Classes
	const classNames =
		'DateSelector' +
		`${size ? ' DateSelector--' + size : ''}` +
		`${label ? ' DateSelector--hasLabel' : ''}` +
		`${props.disabled ? ' DateSelector--disabled' : ''}` +
		`${warning ? ' DateSelector--warning' : ''}` +
		`${invalid ? ' DateSelector--invalid' : ''}` +
		`${className !== undefined ? ' ' + className : ''}`

	// Return Luxon parsed date
	const handleOnChange = (date: Date | null) => {
		if (date && DateTime.fromJSDate(date).isValid) onChange(DateTime.fromJSDate(date))
		else onChange(null)
	}

	// Try to parse date
	let tmpSelected: Date | undefined
	let tmpMinDate: Date | undefined
	let tmpMaxDate: Date | undefined
	if (selected) tmpSelected = parseDate(selected)?.toJSDate()
	if (minDate) tmpMinDate = parseDate(minDate)?.toJSDate()
	if (maxDate) tmpMaxDate = parseDate(maxDate)?.toJSDate()

	if (!loadedLocale) {
		return (
			<div className={classNames}>
				<Loading />
			</div>
		) // Or some other placeholder/fallback
	}

	return (
		<div className={classNames}>
			{(label || icon || showTooltip) && (
				<div className="DateSelector_Label">
					{icon && <MaterialSymbol name={icon} fill="1" />}
					{label && <label htmlFor={name}>{label}</label>}
					{showTooltip && (
						<Tooltip content={tooltipText} disabled={tooltipText.length <= 0}>
							<MaterialSymbol className="DateSelector_Label_Help" name="help" fill="1" />
						</Tooltip>
					)}
				</div>
			)}
			<div className="DateSelector_Wrapper">
				<DatePicker
					name={name}
					ref={innerRef}
					locale={loadedLocale}
					dateFormat={dateFormat}
					customInput={customInput}
					{...(placeholder ? { placeholderText: placeholder } : {})}
					{...(tmpMinDate ? { minDate: tmpMinDate } : {})}
					{...(tmpMaxDate ? { maxDate: tmpMaxDate } : {})}
					{...(tmpSelected ? { selected: tmpSelected } : {})}
					{...(selectsMultiple ? { selectsMultiple } : {})}
					onChange={date => handleOnChange(date)}
					clearButtonClassName="DateSelector_ClearButton"
					clearButtonTitle={t('button:clear')}
					{...rest}
				/>
			</div>
			{invalid && invalidMessage && (
				<p className="DateSelector_InvalidMessage">
					<MaterialSymbol name="cancel" fill="1" /> {invalidMessage}
				</p>
			)}
		</div>
	)
}

// Custom date time selector
interface CustomTimeSelectorProps extends HTMLAttributes<HTMLDivElement> {
	value?: string // Assuming value is a string, adjust the type if necessary
	onClick?: () => void // Adjust if your onClick handler expects arguments
	className?: string
}
// Custom date selector
export const CustomTimeSelector = forwardRef<HTMLDivElement, CustomTimeSelectorProps>(({ value, onClick, className }, ref) => {
	return (
		<div className={className} onClick={onClick} ref={ref}>
			{value}
		</div>
	)
})
CustomTimeSelector.displayName = 'CustomTimeSelector'

export default forwardRef(DateSelector)
