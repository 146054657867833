import type { AnchorHTMLAttributes, ReactNode } from 'react'
import './ReactLink.scss'

// Router
import { type LinkProps as RouterLinkProps, Link as RouterLink, useLocation } from 'react-router-dom'

// Atoms
import MaterialSymbol from '../../atoms/materialSymbol/MaterialSymbol'

interface CommonLinkProps {
	children: ReactNode
	className?: string
	icon?: string
	iconPosition?: 'left' | 'right'
	block?: boolean
}

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	href?: string
}

interface InternalLinkProps extends Omit<RouterLinkProps, 'to'> {
	href?: string
	isInternal?: boolean
	active?: boolean
	disabled?: boolean
}

interface FunctionLinkProps {
	onClick?: () => void
}

type ReactLinkProps = CommonLinkProps & ExternalLinkProps & InternalLinkProps & FunctionLinkProps

const ReactLink = (props: ReactLinkProps) => {
	const { href, isInternal = false, className, disabled, active, block, onClick, icon, iconPosition = 'left', ...rest } = props

	const { pathname } = useLocation()

	const classNames =
		`ReactLink${className ? ' ' + className : ''}` +
		`${block ? ' ReactLink--block' : ''}` +
		`${pathname === href || active ? ' ReactLink--active' : ''}` +
		`${disabled ? ' ReactLink--disabled' : ''}` +
		`${props?.target === '_blank' && !isInternal ? ' ReactLink--blank' : ''}` +
		`${icon ? ' ReactLink--icon' : ''}` +
		`${iconPosition === 'right' ? ' ReactLink--icon-right' : ''}`

	if (onClick) {
		return (
			<span className={classNames} {...rest} onClick={onClick}>
				{icon && iconPosition === 'left' && <MaterialSymbol name={icon} />}
				<span>{props.children}</span>
				{icon && iconPosition === 'right' && <MaterialSymbol name={icon} />}
			</span>
		)
	}
	if (href?.startsWith('http')) {
		// External link
		return (
			<a className={classNames} {...rest} href={href} target={`${isInternal ? '_self' : '_blank'}`} rel="noreferrer">
				{icon && iconPosition === 'left' && <MaterialSymbol name={icon} />}
				<span>{props.children}</span>
				{icon && iconPosition === 'right' && <MaterialSymbol name={icon} />}
			</a>
		)
	}
	if (href) {
		// Internal link
		return (
			<RouterLink className={classNames} {...rest} to={href}>
				{icon && iconPosition === 'left' && <MaterialSymbol name={icon} />}
				<span>{props.children}</span>
				{icon && iconPosition === 'right' && <MaterialSymbol name={icon} />}
			</RouterLink>
		)
	}
}

export default ReactLink
