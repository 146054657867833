import { DateTime } from 'luxon'

type InputDate = string | Date | DateTime
type OutputPreference = 'js-date' | 'luxon'
interface ITime {
	hour: number
	minute: number
	second: number
	millisecond: number
}

// Overload signatures
function parseDate(input: InputDate, outputPreference: 'luxon', zone?: string): DateTime
function parseDate(input: InputDate, outputPreference: 'js-date', zone?: string): Date
function parseDate(input: InputDate): DateTime
// Implementation signature
function parseDate(input: InputDate, outputPreference: OutputPreference = 'luxon', zone: string = 'local'): Date | DateTime {
	let result: DateTime

	// Check the type of the input and convert it to Luxon DateTime
	if (typeof input === 'string') {
		result = DateTime.fromISO(input, { zone })
		if (!result.isValid) {
			result = DateTime.fromRFC2822(input, { zone })
		}
		if (!result.isValid) {
			result = DateTime.fromJSDate(new Date(input), { zone })
		}
	} else if (input instanceof Date) {
		result = DateTime.fromJSDate(input, { zone })
	} else if (input instanceof DateTime) {
		result = input.setZone(zone)
	} else {
		throw new Error('Invalid input type')
	}

	// If the parsed date is not valid, throw an error
	if (!result.isValid) {
		throw new Error(`Invalid date: ${result.invalidExplanation}`)
	}

	// Depending on the output preference, return JavaScript Date or Luxon DateTime
	if (outputPreference === 'js-date') {
		return result.toJSDate()
	}
	return result
}
export { parseDate }

// Converts date object to valid ISO string.
export const formatServerDate = (dateTime: string | DateTime | Date) => {
	// Parse date
	const tmpDate = parseDate(dateTime, 'luxon')

	// Return ISO 8601 string
	return tmpDate.toISO()
}

// Get Luxon today's date
export const dateNow = () => DateTime.now()

// Modifies time part of the given date
// If time object is empty it will reset time to 0:0:0:0
export const modifyTime = (dateTime: string | Date | DateTime, time: ITime) => {
	// Parse date
	const tmpDate = parseDate(dateTime, 'luxon')

	tmpDate.set({ hour: time.hour || 0, minute: time.minute || 0, second: time.second || 0, millisecond: time.millisecond || 0 })

	return tmpDate
}

// Returns time part of the date as an object
export const getTimeAsObject = (dateTime: string | DateTime | Date) => {
	// Parse date
	const tmpDate = parseDate(dateTime, 'luxon')

	const tmpTime = {
		hour: tmpDate.hour,
		minute: tmpDate.minute,
		second: tmpDate.second,
		millisecond: tmpDate.millisecond
	}
	return tmpTime
}

// Returns time difference between now and given date.
export const getTimeDiff = (targetDate: string | DateTime | Date) => {
	// Parse date
	const tmpTargetDate = parseDate(targetDate, 'luxon')

	const diff = DateTime.now().diff(tmpTargetDate, ['years', 'months', 'days', 'hours'])
	return diff.toObject()
}

// For preset please check: https://moment.github.io/luxon/#/formatting?id=presets
export const formatDisplayDate = (isoCode: string, dateTime: any, formatPreset = DateTime.DATE_SHORT) => {
	// Parse date
	const tmpDate = parseDate(dateTime)

	// Format date
	if (tmpDate) return tmpDate.setLocale(isoCode).toLocaleString(formatPreset)

	return dateTime
}

// Returns end of the day in UTC
export const getEndOfDayUTC = (): Date => {
	const now = DateTime.utc()
	const endOfDay = now.endOf('day')
	return endOfDay.toJSDate()
}

// Get current javascript date with time
export const getCurrentDateWithTime = (): Date => {
	const date = new Date()
	date.setMinutes(0, 0, 0) // Reset minutes, seconds and milliseconds
	return date
}