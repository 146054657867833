import { useEffect, useRef, useState } from 'react'
import './SignUp.scss'

// Cloudflare Turnstile
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile'

// Router
import { useNavigate } from 'react-router-dom'

// Localization
import { useTranslation } from 'react-i18next'

// Zustand
import useUserStore from '../../../store/userStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { signUpSchema } from '../../../validation/signUpSchema'

// Atoms
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Checkbox from '../../../ruya-shared/shared/ui/atoms/checkBox/CheckBox'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Template
import Public from '../../templates/public/Public'

// Utilities
import { getSupportedTurnstileLanguage } from '../../../ruya-shared/shared/utils/commonHelper'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// API
import apiPublic from '../../../api/apiPublic'

type SignUpFormValues = {
	displayName: string
	email: string
	password: string
	confirmPassword: string
	captchaToken?: string
	termsAndConditions: boolean
}

const SignUp = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Turnstile
	const captchaTokenRef = useRef<TurnstileInstance>(null)

	// Zustand
	const userStore = useUserStore()
	const languageStore = useLanguageStore()

	// Router
	const navigate = useNavigate()

	const [termsAndConditionsURL, setTermsAndConditionsURL] = useState(null)

	// Get terms and conditions URL
	useEffect(() => {
		// Build query string
		const queryString = `?lang=${languageStore.selectedLanguage?.isoCode}&tag=terms-and-conditions`
		apiPublic.get(`/page/get-page-url-by-tag${queryString}`).then(response => {
			const res = response.data

			if (res.status !== 'success') return

			setTermsAndConditionsURL(res.data.url)
		})
	}, [])

	// Check authentication
	useEffect(() => {
		if (!userStore.loading) {
			if (userStore.user) {
				// Redirect to dashboard
				navigate(commonSettings.apps.web.paths.journal)
			} else {
				userStore.authenticateUserWithToken()
			}
		}
	}, [userStore.user, userStore.loading])

	// React Hook Form
	const formOptions = { mode: 'onChange', resolver: yupResolver(signUpSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isValid }
	} = useForm<SignUpFormValues>(formOptions)

	// Form submit
	const onSubmit = async (values: SignUpFormValues) => {
		// Register user
		const response = await userStore.registerUser(values)

		if (!response) {
			captchaTokenRef.current?.reset()
		}
	}

	if (!commonSettings.services.isSignUpEnabled)
		return (
			<main>
				<InfoBox type="error" icon="error" text={t('signUp:serviceDisabled')} />
			</main>
		)

	if (userStore.loading || termsAndConditionsURL === null) {
		return (
			<ContentWrapper className="SignUp">
				<H1 isCentered={true}>{t('signUp:header')}</H1>
				<Loading />
			</ContentWrapper>
		)
	}

	return (
		<ContentWrapper className="SignUp">
			<H1 isCentered={true}>{t('signUp:header')}</H1>

			{commonSettings.services.isSignInEnabled && (
				<P size="lg" className="SignUp_SignIn">
					{t('login:doYouHaveAccount')} <ReactLink href="/login">{t('links:clickHereToLogin')}</ReactLink>
				</P>
			)}

			{/* <div className="SignUp_ConnectWith">
				<ButtonContinueWith icon="apple" onClick={() => userStore.authenticateUserApple()}>
					{t('button:continueWithApple')}
				</ButtonContinueWith>

				<ButtonContinueWith icon="google" onClick={() => userStore.authenticateUserWithGoogle()}>
					{t('button:continueWithGoogle')}
				</ButtonContinueWith>
				<ButtonContinueWith icon="facebook" onClick={() => userStore.authenticateUserWithFacebook()}>
					{t('button:continueWithFacebook')}
				</ButtonContinueWith>
			</div>

			<P size="sm" className="SignUp_OR_Email">
				{t('signUp:orSignUpWithEmail')}
			</P> */}

			<Form className="SignUp_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<FormGroup>
					<Input
						icon="id_card"
						type="text"
						placeholder={t('form:displayName.placeholder')}
						label={t('form:displayName.label')}
						autoComplete="nickname"
						invalid={Boolean(errors?.displayName?.message)}
						invalidMessage={errors?.displayName?.message?.toString()}
						{...register('displayName')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						icon="email"
						type="text"
						placeholder={t('form:email.placeholder')}
						label={t('form:email.label')}
						autoComplete="email"
						invalid={Boolean(errors?.email?.message)}
						invalidMessage={errors?.email?.message?.toString()}
						{...register('email')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						icon="password"
						type="password"
						placeholder={t('form:password.placeholder')}
						label={t('form:password.label')}
						autoComplete="new-password"
						invalid={Boolean(errors?.password?.message)}
						invalidMessage={errors?.password?.message?.toString()}
						{...register('password')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						icon="password"
						type="password"
						placeholder={t('form:confirmPassword.placeholder')}
						label={t('form:confirmPassword.label')}
						autoComplete="new-password"
						invalid={Boolean(errors?.confirmPassword?.message)}
						invalidMessage={errors?.confirmPassword?.message?.toString()}
						{...register('confirmPassword')}
					/>
				</FormGroup>

				{userStore.error && (
					<FormGroup>
						<p className="SignUp_Error">{userStore.error}</p>
					</FormGroup>
				)}

				<FormGroup>
					<Controller
						control={control}
						name="captchaToken"
						render={({ field }) => {
							const { onChange } = field
							return (
								<Turnstile
									ref={captchaTokenRef}
									siteKey={commonSettings.cloudFlare.turnstile.turnstileSiteKey}
									scriptOptions={{ async: true, defer: true }}
									options={{
										tabIndex: 3,
										language: getSupportedTurnstileLanguage(languageStore.selectedLanguage?.isoCode)
									}}
									onError={() => captchaTokenRef.current?.reset()}
									onSuccess={token => onChange(token)}
								/>
							)
						}}
					/>
				</FormGroup>

				<FormGroup className="SignUp_TermsAndConditions">
					<ReactLink href={`${commonSettings.apps.next.url}${termsAndConditionsURL}`} target="_blank">
						{t('links:termsAndConditions')}
					</ReactLink>
					<Checkbox label={t('form:confirmTermsAndConditions:label')} {...register('termsAndConditions')} />
				</FormGroup>

				<FormGroup>
					<Button
						type="submit"
						block={true}
						disabled={userStore.loading || !isValid || termsAndConditionsURL === null}
						loading={userStore.loading}
						loadingText={t('button:loading')}
						text={t('button:signUp')}
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Public(SignUp)
