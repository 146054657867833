import './HistoryBackLink.scss'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

// Router
import { useNavigate } from 'react-router-dom'

// Localization
import { useTranslation } from 'react-i18next'

interface HistoryBackLinkProps {
	href?: string
}

const HistoryBackLink = (props: HistoryBackLinkProps) => {
	const { href } = props

	const navigate = useNavigate()

	// Translation Hook
	const { t } = useTranslation()

	return (
		<div className="HistoryBackLink" onClick={() => (href ? navigate(href) : navigate(-1))}>
			<MaterialSymbol name="chevron_left" />
			<span>{t('links:back')}</span>
		</div>
	)
}

export default HistoryBackLink
