// Config
import { getCookie } from 'cookies-next'
import languageSettings from '../config/languageSettings'

// Types
import type { ILanguage, ILanguageFontInfo } from '../types/LanguageTypes'

// An HTTP content negotiator for Node.js
import langParser from 'accept-language-parser'

// Validation
import { languageCodeInPathRegEx } from '../validation/regularExpressions'

// Returns active languages as array
export const getActiveLanguages = languageSettings.languages.filter(lang => lang?.active === true) || []

// Returns language code by given full iso code
export const getLanguageCode = (isoCode: string) => {
	const [languageCode] = isoCode.split('-')
	return languageCode
}

// Returns region code by given full iso code
export const getRegionCode = (isoCode: string) => {
	const [, regionCode] = isoCode.split('-')
	return regionCode
}

// Returns language object by given full iso code
export const getLanguageObject = (isoCode: string): ILanguage | null => {
	// Validate input
	if (!isoCode || typeof isoCode !== 'string') {
		console.error('Invalid ISO code provided:', isoCode)
		return null
	}

	// Check isoCode in available languages
	return getActiveLanguages.find(lang => lang?.isoCode === isoCode) || null
}

// Returns language object by given short iso code
export const getLanguageObjectByShortCode = (isoCode: string): ILanguage | null => {
	// Check isoCode in available languages
	return getActiveLanguages.find(lang => lang?.isoCode.split('-')[0] === isoCode.split('-')[0]) || null
}

// Gets browser first preferred language
export const getLanguage = (): ILanguage => {
	let languageObject: ILanguage | null = null

	// Check the user's local storage if the user has already selected a language
	const userSelection: string = getCookie(languageSettings.cookie.cookieName) || languageSettings.defaultLanguage

	// If userSelection exists check if it also exists in active languages
	languageObject = getLanguageObject(userSelection)

	// If language exists return the object
	if (languageObject) return languageObject

	// Get Navigator interface
	const browser = window.navigator

	// If the user doesn't have a previous selection or the selection doesn't exist or is active any more try auto detect browser language
	if (languageSettings.autoDetectLanguage) {
		// Check browser default language
		languageObject = getLanguageObject(browser.language)

		// If language exists return the object
		if (languageObject) return languageObject

		// If language not exist and auto detect alternative languages setting is enabled
		if (languageSettings.autoDetectAlternateLanguages) {
			for (let i = 0; i < browser.languages.length; i++) {
				const languageObject = getLanguageObject(browser.languages[i])
				if (languageObject) return languageObject
			}
		}
	}

	// If match short code enabled
	if (languageSettings.autoDetectLanguage && languageSettings.matchShortCode) {
		// Check browser default language by short code
		languageObject = getLanguageObjectByShortCode(browser.language)

		// If language exists return the object
		if (languageObject) return languageObject

		// If language not exist and auto detect alternative languages setting is enabled
		if (languageSettings.autoDetectAlternateLanguages) {
			for (let i = 0; i < browser.languages.length; i++) {
				const languageObject = getLanguageObjectByShortCode(browser.languages[i])
				if (languageObject) return languageObject
			}
		}
	}

	// If we couldn't find any matching language return default language.
	languageObject = getLanguageObject(languageSettings.defaultLanguage) as ILanguage

	return languageObject
}

// Returns language object from cookie
export const getLanguageFromCookie = (request: any): ILanguage | null => {
	// Check cookie
	const languageValue = getCookie(languageSettings.cookie.cookieName, { req: request })

	// If language from cookie is active, return the object
	if (languageValue) return getLanguageObject(languageValue)

	return null
}

// Returns language object from headers
export const getLanguageFromHeaders = (acceptLanguage: string | undefined): ILanguage | null => {
	if (languageSettings.autoDetectLanguage && acceptLanguage) {
		// Check accept-language header
		const parsedLanguages = langParser.parse(acceptLanguage)

		// Check first language from header is active
		let languageObject = getLanguageObject(`${parsedLanguages[0]?.code}-${parsedLanguages[0]?.region}`)

		// If language exists return the object
		if (languageObject) return languageObject

		// If language not exist and auto detect alternative languages setting is enabled
		if (languageSettings.autoDetectAlternateLanguages) {
			for (let i = 0; i < parsedLanguages.length; i++) {
				const languageObject = getLanguageObject(`${parsedLanguages[i]?.code}-${parsedLanguages[i]?.region}`)
				if (languageObject) return languageObject
			}
		}

		// If match short code enabled and language not found by full code
		if (languageSettings.matchShortCode) {
			// Check browser default language by short code
			languageObject = getLanguageObjectByShortCode(parsedLanguages[0]?.code)

			// If language exists return the object
			if (languageObject) return languageObject

			// If language not exist and auto detect alternative languages setting is enabled
			if (languageSettings.autoDetectAlternateLanguages) {
				for (let i = 0; i < parsedLanguages.length; i++) {
					const languageObject = getLanguageObjectByShortCode(parsedLanguages[i]?.code)
					if (languageObject) return languageObject
				}
			}
		}
	}

	return null
}

// Get language from path string
export const getValidLanguageFromPath = (path: string): string | null => {
	// Execute the regex to capture the language code if it matches
	const match = languageCodeInPathRegEx.exec(path)

	// If there is a match, extract the language code and check against the active languages
	if (match) {
		const isoCode = match[1] // Capture the language code part from the regex match
		const languageExists = getActiveLanguages.find(lang => lang.isoCode === isoCode)

		// If the language code is found in the active languages, return it
		if (languageExists) {
			return isoCode
		}
	}

	// If no match or not found in the active languages, return null
	return null
}

// Get language slogan from AppConfig by given iso code and cache result
export const getLanguageSlogan = (isoCode: string): string => {
	// Check isoCode in available languages
	const languageObject = getLanguageObject(isoCode)

	// If language exists return the object
	if (languageObject) return languageObject.slogan

	return 'Vision, Sight.'
}

export const getLanguageFont = (isoCode: string): { fontFace: string; fontName: string; fontURL: string } | null => {
	// Get language object
	const languageObject = getLanguageObject(isoCode) as ILanguage
	if (!languageObject) return null

	// Get font info
	const fontInfo = languageSettings.languageFontMapping[languageObject.defaultScript] as ILanguageFontInfo
	if (!fontInfo) return null

	// Generate subsets
	const subsets = languageObject.subsets.join(',')

	// Generate font face
	// const fontFace = `@font-face {font-family: '${fontInfo.fontName}';src: url('${fontInfo.fontURL}&subset=${subsets}&display=swap');font-weight: 400;font-display: swap;}@font-face {font-family: '${fontInfo.fontName}';src: url('${fontInfo.fontURL}&subset=${subsets}&display=swap');font-weight: 500;font-display: swap;}@font-face {font-family: '${fontInfo.fontName}';src: url('${fontInfo.fontURL}&subset=${subsets}&display=swap');font-weight: 600;font-display: swap;}@font-face {font-family: '${fontInfo.fontName}';src: url('${fontInfo.fontURL}&subset=${subsets}&display=swap');font-weight: 700;font-display: swap;}`
	// Import font face
	const fontFace = `@import url('${fontInfo.fontURL}&subset=${subsets}&display=swap');`

	// Generate font name
	const fontName = `${fontInfo.fontName}, ${fontInfo.fallback}`

	return { fontFace, fontName, fontURL: fontInfo.fontURL }
}
