import './ContentWrapper.scss'

interface ContentWrapperProps {
	className?: string
	children: React.ReactNode
}

const ContentWrapper = (props: ContentWrapperProps) => {
	const { children, className } = props

	return (
		<main className={`ContentWrapper${className ? ' ' + className : ''}`}>
			<div className="ContentWrapper_Content">{children}</div>
		</main>
	)
}

export default ContentWrapper
