import './TimelineSeparator.scss'

interface TimelineSeparatorProps {
	size?: 'sm'
}

const TimelineSeparator = (props: TimelineSeparatorProps) => {
	const { size } = props

	const classNames = 'TimelineSeparator' + (size ? ' TimelineSeparator--' + size : '')

	return <span className={classNames} />
}

export default TimelineSeparator
