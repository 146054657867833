import './CurrentLanguageButton.scss'

// Router
import { useNavigate } from 'react-router-dom'

// Zustand
import useLanguageStore from '../../../store/languageStore'

// Shared
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

interface CurrentLanguageButtonProps {
	shape?: '1x1' | '4x3'
}

const CurrentLanguageButton = (props: CurrentLanguageButtonProps) => {
	// Props (shape can be 1x1 or 4x3)
	const { shape = '4x3', ...rest } = props

	// Zustand
	const languageStore = useLanguageStore()

	// Router
	const navigate = useNavigate()

	// Handle language click
	const handleLanguageClick = () => {
		if (languageSettings.allowChangeLanguage) {
			navigate('/language')
		}
	}

	return (
		<div className="CurrentLanguageButton" {...rest} onClick={handleLanguageClick}>
			{languageSettings.showCountryFlag && (
				<span
					className="CurrentLanguageButton_Flag"
					style={{
						backgroundImage: `url(${commonSettings.apps.cdn.url}/assets/flags/${shape}/${languageStore.selectedLanguage?.flag})`
					}}
				/>
			)}
			{languageStore.selectedLanguage?.displayName}
		</div>
	)
}

export default CurrentLanguageButton
