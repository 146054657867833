import afZA from './af-ZA/index'
import arSA from './ar-SA/index'
import beBY from './be-BY/index'
import bgBG from './bg-BG/index'
import bnBD from './bn-BD/index'
import bsBA from './bs-BA/index'
import cyGB from './cy-GB/index'
import deAT from './de-AT/index'
import deCH from './de-CH/index'
import deDE from './de-DE/index'
import elGR from './el-GR/index'
import enAU from './en-AU/index'
import enCA from './en-CA/index'
import enGB from './en-GB/index'
import enIE from './en-IE/index'
import enIN from './en-IN/index'
import enNZ from './en-NZ/index'
import enSG from './en-SG/index'
import enUS from './en-US/index'
import enZA from './en-ZA/index'
import esES from './es-ES/index'
import etEE from './et-EE/index'
import fiFI from './fi-FI/index'
import frCA from './fr-CA/index'
import frFR from './fr-FR/index'
import gaIE from './ga-IE/index'
import gdGB from './gd-GB/index'
import hrHR from './hr-HR/index'
import huHU from './hu-HU/index'
import idID from './id-ID/index'
import isIS from './is-IS/index'
import itIT from './it-IT/index'
import jaJP from './ja-JP/index'
import koKR from './ko-KR/index'
import ltLT from './lt-LT/index'
import lvLV from './lv-LV/index'
import mrIN from './mr-IN/index'
import neNP from './ne-NP/index'
import nlNL from './nl-NL/index'
import noNO from './no-NO/index'
import plPL from './pl-PL/index'
import ptBR from './pt-BR/index'
import ptPT from './pt-PT/index'
import roRO from './ro-RO/index'
import ruRU from './ru-RU/index'
import soSO from './so-SO/index'
import srRS from './sr-RS/index'
import svSE from './sv-SE/index'
import swTZ from './sw-TZ/index'
import thTH from './th-TH/index'
import trTR from './tr-TR/index'
import ukUA from './uk-UA/index'
import urPK from './ur-PK/index'
import zhCN from './zh-CN/index'
import zhTW from './zh-TW/index'

const resources: Record<string, any> = {
	'af-ZA': afZA,
	'ar-SA': arSA,
	'be-BY': beBY,
	'bg-BG': bgBG,
	'bn-BD': bnBD,
	'bs-BA': bsBA,
	'cy-GB': cyGB,
	'de-AT': deAT,
	'de-CH': deCH,
	'de-DE': deDE,
	'el-GR': elGR,
	'en-AU': enAU,
	'en-CA': enCA,
	'en-GB': enGB,
	'en-IE': enIE,
	'en-IN': enIN,
	'en-NZ': enNZ,
	'en-SG': enSG,
	'en-US': enUS,
	'en-ZA': enZA,
	'es-ES': esES,
	'et-EE': etEE,
	'fi-FI': fiFI,
	'fr-CA': frCA,
	'fr-FR': frFR,
	'ga-IE': gaIE,
	'gd-GB': gdGB,
	'hr-HR': hrHR,
	'hu-HU': huHU,
	'id-ID': idID,
	'is-IS': isIS,
	'it-IT': itIT,
	'ja-JP': jaJP,
	'ko-KR': koKR,
	'lt-LT': ltLT,
	'lv-LV': lvLV,
	'mr-IN': mrIN,
	'ne-NP': neNP,
	'nl-NL': nlNL,
	'no-NO': noNO,
	'pl-PL': plPL,
	'pt-BR': ptBR,
	'pt-PT': ptPT,
	'ro-RO': roRO,
	'ru-RU': ruRU,
	'so-SO': soSO,
	'sr-RS': srRS,
	'sv-SE': svSE,
	'sw-TZ': swTZ,
	'th-TH': thTH,
	'tr-TR': trTR,
	'uk-UA': ukUA,
	'ur-PK': urPK,
	'zh-CN': zhCN,
	'zh-TW': zhTW
}

export default resources
