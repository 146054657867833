import './H1.scss'

interface IH1Props {
	children: React.ReactNode | React.ReactNode[]
	isCentered?: boolean
	className?: string
}

const H1 = (props: IH1Props) => {
	const { children, isCentered, className } = props

	const classNames = 'H1' + `${isCentered ? ' H1--centered' : ''}` + `${className ? ' ' + className : ''}`

	return <h1 className={classNames}>{children}</h1>
}

export default H1
