import './H2.scss'

interface IH2Props {
	children: React.ReactNode | React.ReactNode[]
	isCentered?: boolean
	className?: string
}

const H2 = (props: IH2Props) => {
	const { children, isCentered, className } = props

	const classNames = 'H2' + `${isCentered ? ' H2--centered' : ''}` + `${className ? ' ' + className : ''}`

	return <h2 className={classNames}>{children}</h2>
}

export default H2
