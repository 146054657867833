import * as Yup from 'yup'

// Localization
import i18n from '../i18n'

// Types
import type { ILoginSchema } from '../@types/validation'

// Login Validation Schema
export const loginSchema: Yup.ObjectSchema<ILoginSchema> = Yup.object({
	email: Yup.string().required(i18n.t('form:email.required')).email(i18n.t('form:email.format')),
	password: Yup.string().required(i18n.t('form:password.required')),
	captchaToken: Yup.string().required(i18n.t('form:captchaToken.required'))
}).required()
