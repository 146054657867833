import { useState, forwardRef, type InputHTMLAttributes, type SetStateAction, type KeyboardEvent } from 'react'
import './InputWithButton.scss'

// Atoms
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

interface InputWithButtonProps extends InputHTMLAttributes<HTMLInputElement> {
	className?: string
	placeholder?: string
	disabled?: boolean
	loading?: boolean
	buttonText?: string
	buttonIcon?: string
	iconPosition?: 'left' | 'right'
	onButtonClick?: (value: string) => void
	errorText?: string | null
}

const InputWithButton = forwardRef<HTMLInputElement, InputWithButtonProps>((props, ref) => {
	// Props
	const {
		className,
		disabled,
		loading,
		buttonText,
		iconPosition,
		buttonIcon,
		placeholder,
		onButtonClick = () => {},
		errorText = null,
		...rest
	} = props

	// State for input value
	const [inputValue, setInputValue] = useState('')

	// Classes
	const classNames = 'InputWithButton' + `${className ? ' ' + className : ''}` + `${disabled ? ' Input--disabled' : ''}`

	// Handle input change
	const handleInputChange = (e: { target: { value: SetStateAction<string> } }) => {
		setInputValue(e.target.value)
	}

	// Modified click handler for the button
	const handleButtonOnClick = () => {
		onButtonClick(inputValue)
	}

	// Handle Enter key press in the input
	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onButtonClick(inputValue)
		}
	}

	return (
		<div className={classNames}>
			<div className="InputWithButton_Wrapper">
				<div>
					<input
						placeholder={placeholder}
						onKeyDown={handleKeyDown}
						value={inputValue}
						onChange={handleInputChange}
						ref={ref}
						{...(disabled ? { disabled } : {})}
						{...rest}
					/>
				</div>
				<button type="button" onClick={handleButtonOnClick} {...(disabled ? { disabled } : {})}>
					{loading && <MaterialSymbol name="progress_activity" className="css-spin" />}
					{!loading && iconPosition === 'left' && buttonIcon && <MaterialSymbol name={buttonIcon} />}
					{!loading && buttonText}
					{!loading && iconPosition === 'right' && buttonIcon && <MaterialSymbol name={buttonIcon} />}
				</button>
			</div>
			{errorText && (
				<div className="InputWithButton_Error">
					<MaterialSymbol name="error" /> {errorText}
				</div>
			)}
		</div>
	)
})

InputWithButton.displayName = 'InputWithButton'
export default InputWithButton
