// Glide
import type Glide from '@glidejs/glide'
import type { Components } from '@glidejs/glide/components'

interface Events {
	on(event: string | string[], handler: (...args: any[]) => void): void
}

const coverFlowPlugin = (selectorClass: string) => {
	if (!selectorClass) {
		throw new Error('CoverFlowPlugin: selector is required')
	}

	// Remove the dot or from the selector
	const selectorClassName = selectorClass.replace('.', '')

	return function CoverFlow(glide: Glide, components: Components, events: Events) {
		const coverFlow = {
			tilt(slide: HTMLElement) {
				const slideElement = slide.querySelector(selectorClass) as HTMLElement
				if (slideElement) {
					slideElement.style.transform = 'perspective(1500px) rotateY(0deg)'
					slideElement.classList.remove(`${selectorClassName}--prev`)
					slideElement.classList.remove(`${selectorClassName}--next`)
				}
				this.tiltPrevElements(slide)
				this.tiltNextElements(slide)
			},
			tiltPrevElements(slide: HTMLElement) {
				const prevElements = (function getPrevElements(startElement: HTMLElement) {
					const elements: HTMLElement[] = []
					let element = startElement.previousElementSibling as HTMLElement
					while (element) {
						elements.push(element)
						element = element.previousElementSibling as HTMLElement
					}
					return elements
				})(slide)

				for (let index = 0; index < prevElements.length; index++) {
					const slideElement = prevElements[index].querySelector(selectorClass) as HTMLElement
					if (slideElement) {
						slideElement.style.transformOrigin = '100% 50%'
						slideElement.style.transform = `perspective(1500px) rotateY(${10 * Math.max(index, 2)}deg)`
						slideElement.classList.remove(`${selectorClassName}--next`)
						slideElement.classList.add(`${selectorClassName}--prev`)
					}
				}
			},
			tiltNextElements(slide: HTMLElement) {
				// Function to get next elements
				const nextElements = (function getNextElements(startElement: HTMLElement) {
					const elements: HTMLElement[] = []
					let element = startElement.nextElementSibling as HTMLElement
					while (element) {
						elements.push(element)
						element = element.nextElementSibling as HTMLElement
					}
					return elements
				})(slide)

				for (let index = 0; index < nextElements.length; index++) {
					const slideElement = nextElements[index].querySelector(selectorClass) as HTMLElement
					if (slideElement) {
						slideElement.style.transformOrigin = '0% 50%'
						slideElement.style.transform = `perspective(1500px) rotateY(${-10 * Math.max(index, 2)}deg)`
						slideElement.classList.remove(`${selectorClassName}--prev`)
						slideElement.classList.add(`${selectorClassName}--next`)
					}
				}
			}
		}

		events.on(['mount.after', 'run'], () => {
			// @ts-ignore
			coverFlow.tilt(components.Html.slides[glide.index])
		})

		return coverFlow
	}
}

export default coverFlowPlugin