// Localization
import { useTranslation } from 'react-i18next'

interface SleepQualityItemProps {
	sleepQuality: number
	className?: string
	showIcon?: boolean
}

interface SleepQualityOption {
	value: number
	text: string
	icon: string
}

const SleepQualityItem = (props: SleepQualityItemProps) => {
	// Destruct props
	const { sleepQuality = 3, className, showIcon, ...rest } = props

	// Translation Hook
	const { t: optionsT } = useTranslation('options')

	// Classes
	const classNames = 'SleepQualityItem' + `${className !== undefined ? ' ' + className : ''}`

	const options: SleepQualityOption[] = [
		{ value: 1, text: optionsT('Poor'), icon: '🙁' },
		{ value: 2, text: optionsT('Fair'), icon: '😐' },
		{ value: 3, text: optionsT('Good'), icon: '🙂' },
		{ value: 4, text: optionsT('Very Good'), icon: '😄' },
		{ value: 5, text: optionsT('Excellent'), icon: '🤩' }
	]

	const selectedOption = options.find(option => option.value === sleepQuality)

	if (!selectedOption) return null

	return (
		<div className={classNames}>
			{selectedOption.icon} {selectedOption?.text}
		</div>
	)
}

export default SleepQualityItem
