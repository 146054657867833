import { useEffect, useState } from 'react'
import './DreamInterpretation.scss'

// Template
import Private from '../../templates/private/Private'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Store
import useJournalStore from '../../../store/journalStore'
import useInterpreterStore from '../../../store/interpreterStore'

// Atoms
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Organisms
import DreamQA from '../../organisms/dreamQA/DreamQA'

// Localization
import { useTranslation } from 'react-i18next'

// Types
import type { IDream, IInterpretation, IInterpreter } from '../../../ruya-shared/shared/types'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const DreamInterpretation = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId, dreamId, interpreterId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const interpreterStore = useInterpreterStore()

	// Local state
	const [dream, setDream] = useState<IDream>()
	const [interpreter, setInterpreter] = useState<IInterpreter>()
	const [interpretation, setInterpretation] = useState<IInterpretation>()
	const [interpretationRequested, setInterpretationRequested] = useState(false)

	// Check if all parameters passed correctly
	useEffect(() => {
		if (!sleepSessionId || !dreamId || !interpreterId) {
			navigate('/journal')
		} else {
			// Load sleep session
			journalStore.loadSleepSession(sleepSessionId)
		}
	}, [])

	// When currentSleepSession loaded
	useEffect(() => {
		// Define async function
		const fetchData = async () => {
			// Check if currentSleepSession and interpreterList loaded.
			if (journalStore.currentSleepSession && interpreterStore.interpreterList.length > 0) {
				// Get dream
				const tmpDream = journalStore?.currentSleepSession?.dreams?.find((dream: IDream) => dream._id === dreamId)
				setDream(tmpDream)

				// Get interpreter
				const tmpInterpreter = await interpreterStore.getInterpreter(interpreterId)

				setInterpreter(tmpInterpreter)

				// Find interpretation for current interpreter
				const tmpInterpretation = tmpDream?.interpretations?.find(
					(interpretation: IInterpretation) => interpretation.interpreter?.id?.toString() === interpreterId
				)

				// If found, set interpretation
				if (tmpInterpretation) {
					setInterpretation(tmpInterpretation)
				} else {
					if (!interpretationRequested) {
						setInterpretationRequested(true)
						journalStore.interpret(sleepSessionId, dreamId, interpreterId)
					}
				}
			}
		}

		// Call async function
		fetchData()
	}, [journalStore.currentSleepSession, interpreterStore.interpreterList])

	// Handle interpretation updates and redirection
	useEffect(() => {
		if (interpretation) {
			if (interpretation.isDreamQACompleted) {
				navigate(`/journal/dream/interpretation/${sleepSessionId}/${dreamId}/${interpretation._id?.toString()}`)
			} else {
				// Interpretation is not completed, continue with QA
				setInterpretation(interpretation)
			}
		}
	}, [interpretation])

	// Loading
	if (journalStore.loading) {
		return (
			<ContentWrapper className="DreamInterpretation">
				<Loading />
			</ContentWrapper>
		)
	}

	// Not loaded yet
	if (!journalStore.currentSleepSession || !interpreter) {
		return (
			<ContentWrapper className="DreamInterpretation">
				<Loading />
			</ContentWrapper>
		)
	}

	// Error
	if (journalStore.error) {
		return (
			<ContentWrapper className="DreamInterpretation">
				<HistoryBackLink />
				<InfoBox type="error" icon="error" header={t('errors:errorTitle')} text={journalStore.error} />
			</ContentWrapper>
		)
	}

	// Handle answer
	const handleAnswer = (answer: string) => {
		// Send answer to server
		journalStore.interpret(sleepSessionId, dreamId, interpreterId, answer)
	}

	return (
		<ContentWrapper className="DreamInterpretation">
			<HistoryBackLink href={`${commonSettings.apps.web.paths.journal}/dream/${sleepSessionId}/${dreamId}`} />

			<H1>
				<MaterialSymbol name={interpreter.icon} fill="1" />
				{interpreter?.name}
			</H1>

			<P>{t('journal:interpretAQDescription')}</P>

			<div className="DreamInterpretation_QA">
				<DreamQA interpreter={interpreter} interpretation={interpretation} dream={dream?.dream} handleAnswer={handleAnswer} />
			</div>
		</ContentWrapper>
	)
}

export default Private(DreamInterpretation)
