import * as Yup from 'yup'
import i18n from '../i18n'

const today = new Date()
const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999) // Set to the end of today

export const sleepSessionSchema = Yup.object({
	sleepStart: Yup.date().required(i18n.t('form:sleepStart.required')).max(endOfDay, i18n.t('form:sleepStart.max')),
	sleepDuration: Yup.number()
		.required(i18n.t('form:sleepDuration.required'))
		.min(1, i18n.t('form:sleepDuration.min'))
		.max(12, i18n.t('form:sleepDuration.max')),
	sleepQuality: Yup.number()
		.required(i18n.t('form:sleepQuality.required'))
		.min(1, i18n.t('form:sleepQuality.min'))
		.max(5, i18n.t('form:sleepQuality.max'))
}).required()

export const sleepSessionUpdateSchema = Yup.object({
	sleepSessionId: Yup.string().required(i18n.t('validation:sleepSessionId.required')),
	sleepStart: Yup.date().required(i18n.t('form:sleepStart.required')).max(endOfDay, i18n.t('form:sleepStart.max')),
	sleepDuration: Yup.number()
		.required(i18n.t('form:sleepDuration.required'))
		.min(1, i18n.t('form:sleepDuration.min'))
		.max(12, i18n.t('form:sleepDuration.max')),
	sleepQuality: Yup.number()
		.required(i18n.t('form:sleepQuality.required'))
		.min(1, i18n.t('form:sleepQuality.min'))
		.max(5, i18n.t('form:sleepQuality.max'))
}).required()

export const addDreamSchema = Yup.object({
	sleepSessionId: Yup.string().required(i18n.t('validation:sleepSessionId.required')),
	dream: Yup.string().required(i18n.t('form:dream.required')),
	isLucidDream: Yup.boolean().required(i18n.t('form:isLucidDream.required')),
	isColor: Yup.boolean().required(i18n.t('form:isColor.required')),
	isRecurring: Yup.boolean().required(i18n.t('form:isRecurring.required'))
}).required()

export const updateDreamSchema = Yup.object({
	title: Yup.string().required(i18n.t('form:dreamTitle.required')),
	dream: Yup.string().required(i18n.t('form:dream.required')),
	isLucidDream: Yup.boolean().required(i18n.t('form:isLucidDream.required')),
	isColor: Yup.boolean().required(i18n.t('form:isColor.required')),
	isRecurring: Yup.boolean().required(i18n.t('form:isRecurring.required'))
}).required()

export const dreamUpdateSchema = Yup.object({
	_id: Yup.string().required(i18n.t('validation:dreamId.required')),
	sleepStart: Yup.date().required(i18n.t('form:sleepStart.required')).max(endOfDay, i18n.t('form:sleepStart.max')),
	sleepDuration: Yup.number()
		.required(i18n.t('form:sleepDuration.required'))
		.min(1, i18n.t('form:sleepDuration.min'))
		.max(12, i18n.t('form:sleepDuration.max')),
	sleepQuality: Yup.number()
		.required(i18n.t('form:sleepQuality.required'))
		.min(1, i18n.t('form:sleepQuality.min'))
		.max(5, i18n.t('form:sleepQuality.max')),
	dreams: Yup.array()
		.of(
			Yup.object().shape({
				dream: Yup.string().required(i18n.t('form:dream.required')),
				title: Yup.string().required(i18n.t('form:dreamTitle.required')),
				isLucidDream: Yup.boolean().required(i18n.t('form:isLucidDream.required')),
				isColor: Yup.boolean().required(i18n.t('form:isColor.required')),
				isRecurring: Yup.boolean().required(i18n.t('form:isRecurring.required'))
			})
		)
		.min(1, i18n.t('form:dreams.min'))
		.required(i18n.t('form:dreams.required'))
}).required()
