// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'

// Types
import type { JournalDataState, JournalState } from '../@types/journal'

// Utils
import { apiErrorHandler, errorHandler } from '../ruya-shared/shared/utils/errorHelper'

const initialState: JournalDataState = {
	sleepSessionsData: {
		page: 0,
		pageSize: 10,
		totalPages: 1,
		totalRecords: 0,
		list: []
	},
	currentSleepSession: null,
	interpreting: false,
	loadingFavorite: null,
	loadingInterpretation: false,
	loading: false,
	error: null
}

const useJournalStore = create<JournalState>((set, get) => ({
	...initialState,
	setLoading: loading => set({ loading }),
	setApiError: error => set({ error }),
	loadSleepSessions: async () => {
		// Reset state
		set({ ...initialState })

		// Set loading
		set({ loading: true })

		// Get pagination from sleepSessionsData
		const { page, pageSize } = get().sleepSessionsData

		try {
			const { data, status } = await apiProtected.get('/journal?page=' + page + '&pageSize=' + pageSize)

			// Check axios error
			if (status !== 200) set({ error: 'Error fetching sleep sessions', loading: false })

			// Check API error
			if (data.status !== 'success') set({ error: data.message, loading: false })

			set({ sleepSessionsData: data.data, loading: false })
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
		}
	},
	loadMoreSleepSessions: async () => {
		set({ loading: true, error: null })

		// Get pagination from sleepSessionsData
		const { page, pageSize, totalPages } = get().sleepSessionsData

		// Check if there are more pages
		if (page < totalPages) {
			try {
				const { data, status } = await apiProtected.get('/journal?page=' + (page + 1) + '&pageSize=' + pageSize)

				// Check axios error
				if (status !== 200) set({ error: 'Error fetching sleep sessions', loading: false })

				// Check API error
				if (data.status !== 'success') set({ error: data.message, loading: false })

				set({
					sleepSessionsData: {
						...data.data,
						list: [...get().sleepSessionsData.list, ...data.data.list]
					},
					loading: false
				})
			} catch (error) {
				set({ error: errorHandler(error), loading: false })
			}
		} else {
			set({ loading: false })
		}
	},
	addSleepSession: async (sleepStart, sleepDuration, sleepQuality) => {
		set({ loading: true, error: null })

		try {
			// Create sleep session
			const response = await apiProtected.post('/journal', {
				sleepStart,
				sleepDuration,
				sleepQuality
			})

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}
			set({ loading: false })
			return response.data.data._id
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	updateSleepSession: async (sleepSessionId, sleepStart, sleepDuration, sleepQuality) => {
		set({ loading: true, error: null })

		try {
			// Update sleep session
			const response = await apiProtected.put('/journal', {
				sleepSessionId,
				sleepStart,
				sleepDuration,
				sleepQuality
			})

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}
			set({ loading: false, currentSleepSession: response.data.data })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	loadSleepSession: async sleepSessionId => {
		set({ loading: true, error: null })

		try {
			const response = await apiProtected.get('/journal/' + sleepSessionId)

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
			} else {
				set({ loading: false, currentSleepSession: response.data.data })
			}
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
		}
	},
	deleteSleepSession: async sleepSessionId => {
		set({ loading: true, error: null })

		try {
			const response = await apiProtected.delete('/journal/' + sleepSessionId)

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}
			set({ loading: false })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	addDream: async (sleepSessionId, dream, isLucidDream, isColor, isRecurring) => {
		set({ loading: true, error: null })

		try {
			// Create dream
			const response = await apiProtected.post('/journal/dream', {
				sleepSessionId,
				dream,
				isLucidDream,
				isColor,
				isRecurring
			})

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return null
			}

			set({ loading: false, currentSleepSession: response.data.data })

			return response.data.data.dreams[response.data.data.dreams.length - 1]._id as string
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return null
		}
	},
	updateDream: async (sleepSessionId, dreamId, title, dream, isLucidDream, isColor, isRecurring) => {
		set({ loading: true, error: null })

		try {
			// Update dream
			const response = await apiProtected.put('/journal/dream', {
				sleepSessionId,
				dreamId,
				title,
				dream,
				isLucidDream,
				isColor,
				isRecurring
			})

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}

			set({ loading: false })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	deleteDream: async (sleepSessionId, dreamId) => {
		set({ loading: true, error: null })

		try {
			const response = await apiProtected.delete('/journal/dream/' + sleepSessionId + '/' + dreamId)

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}

			set({ loading: false })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	interpret: async (sleepSessionId, dreamId, interpreterId, answer) => {
		// Avoid multiple requests
		if (get().loadingInterpretation) return false

		set({ loadingInterpretation: true, error: null })

		try {
			const response = await apiProtected.post('/ai/dream/interpret/qa', { sleepSessionId, dreamId, interpreterId, answer })

			// Check API error
			if (response?.data?.status !== 'success') {
				set({ error: response.data.message, loadingInterpretation: false })
				return false
			}

			// Update current sleep session
			set({ currentSleepSession: response.data.data, loadingInterpretation: false, error: null })
			return true
		} catch (error) {
			// Set error
			set({ loadingInterpretation: false, error: apiErrorHandler(error) })
			return false
		}
	},
	deleteInterpretation: async (sleepSessionId, dreamId, interpretationId) => {
		set({ loading: true, error: null })

		try {
			const response = await apiProtected.delete('/journal/dream/interpretation/' + sleepSessionId + '/' + dreamId + '/' + interpretationId)

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}

			set({ loading: false })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	toggleFavorite: async (sleepSessionId, isFavorite) => {
		set({ loadingFavorite: sleepSessionId, error: null })

		try {
			const response = await apiProtected.put('/journal/favorite', { sleepSessionId, isFavorite })

			// Check API error
			if (response?.data?.status !== 'success') {
				set({ error: response.data.message, loadingFavorite: null })
				return false
			}

			// Update state
			const sleepSessionsData = get().sleepSessionsData
			const sleepSessionIndex = sleepSessionsData.list.findIndex((session: any) => session._id === sleepSessionId)
			if (sleepSessionIndex !== -1) {
				sleepSessionsData.list[sleepSessionIndex].isFavorite = isFavorite
			}

			set({ loadingFavorite: null })
			return true
		} catch (error) {
			// Set error
			set({ loadingFavorite: null, error: apiErrorHandler(error) })
			return false
		}
	}
}))

export default useJournalStore
