import * as Yup from 'yup'
import i18n from '../i18n'
import type { IChangePasswordSchema } from '../@types/validation'

// Change password validation schema
export const changePasswordSchema: Yup.ObjectSchema<IChangePasswordSchema> = Yup.object({
	password: Yup.string().required(i18n.t('form:password.required')),
	newPassword: Yup.string().required(i18n.t('form:newPassword.required')).min(8, i18n.t('form:newPassword.min')),
	confirmNewPassword: Yup.string()
		.oneOf([Yup.ref('newPassword')], i18n.t('form:confirmNewPassword.match'))
		.required(i18n.t('form:confirmNewPassword.required'))
}).required()
