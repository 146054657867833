import type { ISocialSettings } from '../types/SocialTypes'

export const socialSettings: ISocialSettings = {
	defaultImage: 'https://cdn.ruya.co/cdn/assets/meta/social-default-512x512.png',
	defaultHashtags: ['RuyaCoApp', 'DreamJournal', 'DreamInterpretation'],
	facebook: {
		title: 'Facebook',
		url: 'https://www.facebook.com/RuyaCoApp',
		pageId: '170889509441296',
		appId: '197327426752675'
	},
	twitter: {
		title: 'X - Twitter',
		url: 'https://twitter.com/RuyaCoApp',
		username: 'RuyaCoApp'
	},
	instagram: {
		title: 'Instagram',
		url: 'https://www.instagram.com/RuyaCoApp',
		username: 'RuyaCoApp'
	},
	linkedIn: {
		title: 'LinkedIn',
		url: 'https://www.linkedin.com/company/ruyacoapp',
		companyId: '102490745'
	},
	pinterest: {
		title: 'Pinterest',
		url: 'https://www.pinterest.co.uk/RuyaCoApp'
	},
	tikTok: {
		title: 'TikTok',
		url: 'https://www.tiktok.com/@RuyaCoApp'
	},
	youTube: {
		title: 'YouTube',
		url: 'https://www.youtube.com/@ruyacoapp'
	}
}
