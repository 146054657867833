import { useEffect, useRef } from 'react'
import './Login.scss'

// Cloudflare Turnstile
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile'

// Router
import { useLocation, useNavigate } from 'react-router-dom'

// Localization
import { useTranslation } from 'react-i18next'

// Zustand
import useUserStore from '../../../store/userStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { loginSchema } from '../../../validation/loginSchema'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import ButtonContinueWith from '../../../ruya-shared/shared/ui/atoms/buttonContinueWith/ButtonContinueWith'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Template
import Public from '../../templates/public/Public'

// Utils
import { getSupportedTurnstileLanguage } from '../../../ruya-shared/shared/utils/commonHelper'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

type LoginFormValues = {
	email: string
	password: string
	captchaToken: string
}

const Login = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Turnstile
	const captchaTokenRef = useRef<TurnstileInstance>(null)

	// Zustand
	const userStore = useUserStore()
	const languageStore = useLanguageStore()

	// Router
	const navigate = useNavigate()
	const { state } = useLocation()

	// Check authentication
	useEffect(() => {
		if (userStore.user) {
			// If user requested a protected route before login, redirect to that route
			if (state?.from) navigate(state.from)

			// Else redirect to dashboard
			navigate(commonSettings.apps.web.paths.journal)
		} else {
			userStore.authenticateUserWithToken()
		}
	}, [userStore.user])

	// React Hook Form
	const formOptions = { mode: 'onChange', resolver: yupResolver<LoginFormValues>(loginSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isValid }
	} = useForm<LoginFormValues>(formOptions)

	// Form submit
	const onSubmit = async (values: LoginFormValues) => {
		// Login user
		const response = await userStore.authenticateUser(values)

		if (!response) {
			// Reset captcha
			captchaTokenRef.current?.reset()
		}
	}

	return (
		<ContentWrapper className="Login">
			<H1 isCentered={true}>{t('login:header')}</H1>

			{commonSettings.services.isSignUpEnabled && (
				<P size="lg" className="Login_Signup">
					{t('login:dontHaveAccount')} <ReactLink href="/sign-up">{t('links:signUpForFree')}</ReactLink>
				</P>
			)}

			{/* <div className="Login_ConnectWith">
				<ButtonContinueWith icon="apple" onClick={() => userStore.authenticateUserApple()}>
					{t('button:continueWithApple')}
				</ButtonContinueWith>

				<ButtonContinueWith icon="google" onClick={() => userStore.authenticateUserWithGoogle()}>
					{t('button:continueWithGoogle')}
				</ButtonContinueWith>
				<ButtonContinueWith icon="facebook" onClick={() => userStore.authenticateUserWithFacebook()}>
					{t('button:continueWithFacebook')}
				</ButtonContinueWith>
			</div>

			<P size="sm" className="Login_OR_Email">
				{t('login:orSignInWithEmail')}
			</P> */}

			<Form className="Login_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<FormGroup>
					<Input
						icon="mail"
						type="text"
						placeholder={t('form:email.placeholder')}
						label={t('form:email.label')}
						autoComplete="email"
						invalid={Boolean(errors?.email?.message)}
						invalidMessage={errors?.email?.message}
						{...register('email')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						icon="password"
						type="password"
						placeholder={t('form:password.placeholder')}
						label={t('form:password.label')}
						autoComplete="current-password"
						invalid={Boolean(errors?.password?.message)}
						invalidMessage={errors?.password?.message}
						{...register('password')}
					/>
					{commonSettings.services.isForgotPasswordEnabled && (
						<ReactLink className="Login_Form_ForgotPassword" href="/forgot-password">{t('links:forgotYourPassword')}</ReactLink>
					)}
				</FormGroup>

				{userStore.error && (
					<FormGroup>
						<p className="Login_Error">{userStore.error}</p>
					</FormGroup>
				)}

				<FormGroup>
					<Controller
						control={control}
						name="captchaToken"
						render={({ field }) => {
							const { onChange } = field
							return (
								<Turnstile
									ref={captchaTokenRef}
									siteKey={commonSettings.cloudFlare.turnstile.turnstileSiteKey}
									scriptOptions={{ async: true, defer: true }}
									options={{
										tabIndex: 3,
										language: getSupportedTurnstileLanguage(languageStore.selectedLanguage?.isoCode)
									}}
									onError={() => captchaTokenRef.current?.reset()}
									onSuccess={token => onChange(token)}
								/>
							)
						}}
					/>
				</FormGroup>

				<FormGroup>
					<Button
						type="submit"
						block={true}
						disabled={userStore.loading || !isValid}
						loading={userStore.loading}
						loadingText={t('button:loading')}
						text={t('button:login')}
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Public(Login)
