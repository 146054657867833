import './DreamCard.scss'

// Router
import { useNavigate } from 'react-router-dom'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Types
import type { IDream, IInterpretation, IJournal, ObjectId } from '../../../ruya-shared/shared/types'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Label from '../../../ruya-shared/shared/ui/atoms/label/Label'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import Tag from '../../../ruya-shared/shared/ui/atoms/tag/Tag'

// Store
import useInterpreterStore from '../../../store/interpreterStore'

// Translation
import { useTranslation } from 'react-i18next'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

interface DreamCardProps {
	dream: IDream
	sleepSession: IJournal
}

const DreamCard = ({ dream, sleepSession }: DreamCardProps) => {
	// Store
	const interpreterStore = useInterpreterStore()

	// Navigate
	const navigate = useNavigate()

	// Translation Hook
	const { t } = useTranslation()

	const getInterpreter = (interpreterId: ObjectId) => {
		return interpreterStore?.interpreterList?.find((interpreter: any) => interpreter._id === interpreterId)
	}

	const goToInterpretation = (interpretation: IInterpretation) => {
		if (interpretation.isDreamQACompleted) {
			navigate(`/journal/dream/interpretation/${sleepSession._id}/${dream._id}/${interpretation._id?.toString()}`)
		} else {
			navigate(`/journal/dream-questionnaire/${sleepSession._id}/${dream._id}/${interpretation.interpreter.id}`)
		}
	}

	return (
		<div key={dream._id?.toString()} className="DreamCard">
			<div className="DreamCard_Section">
				<Label className="DreamCard_SubTitle">{t('journal:dreamSubHeader')}</Label>
				<P>{dream.title}</P>
			</div>

			{/* 
			
			TODO: Implement the following sections

			<div className="DreamCard_Section">
				<Label className="DreamCard_SubTitle">{t('journal:symbolsSubHeader')}</Label>
				<div className="DreamCard_Section_List">
					<Tag text="Horse" />
					<Tag text="Snake" />
					<Tag text="Forest" />
				</div>
			</div>

			<div className="DreamCard_Section">
				<Label className="DreamCard_SubTitle">{t('journal:peopleSubHeader')}</Label>
				<div className="DreamCard_Section_List">
					<Tag text="John" />
					<Tag text="Jane" />
					<Tag text="Alex" />
				</div>
			</div> */}

			{dream.interpretations.length > 0 && (
				<div className="DreamCard_Section">
					<Label className="DreamCard_SubTitle">{t('journal:interpretationsSubHeader')}</Label>
					<div className="DreamCard_Section_List">
						{dream.interpretations?.map((interpretation: IInterpretation) => {

							return (
								<div
									key={interpretation._id?.toString()}
									className="DreamCard_Interpretation"
									onClick={() => goToInterpretation(interpretation)}>
									<MaterialSymbol name={interpretation.interpreter?.icon} fill="1" />
									<span className="DreamCard_Interpretation_Name">{interpretation.interpreter?.name}</span>
								</div>
							)
						})}
					</div>
				</div>
			)}
			<div className="DreamCard_Actions">
				<Button
					size="sm"
					color="light"
					text={t('button:viewDream')}
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/${sleepSession._id}/${dream._id}`)}
				/>
				<Button
					size="sm"
					color="light"
					text={t('button:interpret')}
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/select-interpreter/${sleepSession._id}/${dream._id}`)}
				/>
			</div>
		</div>
	)
}

export default DreamCard
