// Zustand
import { create } from 'zustand'
import useUserStore from './userStore'

// API
import apiProtected from '../api/apiProtected'

// Localization
import i18n from '../i18n'

// Types
import type { LanguageDataState, LanguageState } from '../@types/language'
import type { ILanguage } from '../ruya-shared/shared/types'
import type { ApiResponse } from '../@types/global'

// Utils
import { getActiveLanguages, getLanguage } from '../ruya-shared/shared/utils/languageHelper'
import { errorHandler } from '../ruya-shared/shared/utils/errorHelper'
import { writeCookie } from '../ruya-shared/shared/utils/cookieHelper'

// Config
import languageSettings from '../ruya-shared/shared/config/languageSettings'
import useMainStore from './mainStore'

const currentLanguage = getLanguage()

// Initial State
const initialState: LanguageDataState = {
	selectedLanguage: currentLanguage,
	activeLanguages: getActiveLanguages.filter(lang => lang?.isoCode !== currentLanguage.isoCode),
	allLanguages: getActiveLanguages,
	loading: false,
	error: null
}

// Language Store
const useLanguageStore = create<LanguageState>(set => ({
	...initialState,

	// Actions
	setLanguageSelection: (selectedLanguage: ILanguage) => {
		set({
			selectedLanguage,
			activeLanguages: getActiveLanguages.filter(lang => lang?.isoCode !== selectedLanguage.isoCode),
			loading: false,
			error: null
		})
	},
	setLoading: (loading: boolean) =>
		set({
			loading,
			error: null
		}),
	setApiError: (error: string) =>
		set({
			loading: false,
			error
		}),
	reset: () => set(initialState),
	setLanguage: async (isoCode: string) => {
		if (isoCode) {
			// Set loading to true
			set({ loading: true })

			try {
				const selectedLanguage = getActiveLanguages.find((lang: ILanguage) => lang?.isoCode === isoCode)
				const activeLanguages = getActiveLanguages.filter((lang: ILanguage) => lang?.isoCode !== isoCode) || []

				// Update the state
				set({ selectedLanguage, activeLanguages })

				// Update the i18n language
				i18n.changeLanguage(isoCode)

				// Update cookie
				writeCookie(languageSettings.cookie.cookieName, isoCode, languageSettings.cookie.cookieOptions)

				// Update the user language if the user is authenticated
				const user = useUserStore.getState().user
				if (user) {
					// Update the user language in the database
					const { data } = await apiProtected.patch('/user/language', { language: isoCode })

					// Axios data response
					const response = data as ApiResponse

					if (response.status === 'success') {
						// Update the user language in the state
						useUserStore.getState().updateUserLanguage(isoCode)
					} else {
						// Set error
						set({ loading: false, error: response.message })
					}
				}

				// Reload app data
				useMainStore.getState().onAppLoad()

				// Set loading to false
				set({ loading: false })
			} catch (error) {
				// Set error
				set({ loading: false, error: errorHandler(error) })
			}
		}

		// If no isoCode is provided, do nothing
		set({ loading: false })
	}
}))

export default useLanguageStore
