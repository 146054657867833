import accountStatus from './accountStatus.json'
import activation from './activation.json'
import articles from './articles.json'
import button from './button.json'
import changePassword from './changePassword.json'
import common from './common.json'
import cookieBanner from './cookieBanner.json'
import cta from './cta.json'
import dates from './dates.json'
import dialog from './dialog.json'
import email from './email.json'
import errors from './errors.json'
import forgotPassword from './forgotPassword.json'
import form from './form.json'
import home from './home.json'
import info from './info.json'
import journal from './journal.json'
import languages from './languages.json'
import links from './links.json'
import login from './login.json'
import manageCookies from './manageCookies.json'
import navigation from './navigation.json'
import notFound from './notFound.json'
import options from './options.json'
import player from './player.json'
import profile from './profile.json'
import resetPassword from './resetPassword.json'
import signUp from './signUp.json'
import social from './social.json'
import subscriptions from './subscriptions.json'
import userInfo from './userInfo.json'
import validation from './validation.json'

const ukUA = {
	accountStatus,
	activation,
	articles,
	button,
	changePassword,
	common,
	cookieBanner,
	cta,
	dates,
	dialog,
	email,
	errors,
	forgotPassword,
	form,
	home,
	info,
	journal,
	languages,
	links,
	login,
	manageCookies,
	navigation,
	notFound,
	options,
	player,
	profile,
	resetPassword,
	signUp,
	social,
	subscriptions,
	userInfo,
	validation
}

export default ukUA
