// Zustand
import { create } from 'zustand'

// Stores
import useNavigationStore from './navigationStore'
import useInterpreterStore from './interpreterStore'

// Types
import type { MainState } from '../@types/main'

// Utils
import { errorHandler } from '../ruya-shared/shared/utils/errorHelper'

// Initial State
const initialState = {
	loading: false,
	error: null
}

// User Store
const useMainStore = create<MainState>(set => ({
	...initialState,
	// Actions
	setLoading: loading => set({ loading, error: null }),
	setApiError: error => set({ loading: false, error }),
	reset: () => set(initialState),
	// Async actions
	onAppLoad: async () => {
		// Set loading to true
		set({ loading: true })

		try {
			// Load navigation data
			await useNavigationStore.getState().loadNavigations()

			// Load interpreter list
			await useInterpreterStore.getState().loadInterpreterList()

			// Set loading to false
			set({ loading: false })
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })
		}
	}
}))

export default useMainStore
