export const localeImports: Record<string, Promise<any>> = {
	'om-ET': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'af-ZA': import('date-fns/locale/af').then(loc => loc.af),
	'az-AZ': import('date-fns/locale/az').then(loc => loc.az),
	'id-ID': import('date-fns/locale/id').then(loc => loc.id),
	'ms-MY': import('date-fns/locale/ms').then(loc => loc.ms),
	'su-ID': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'bs-BA': import('date-fns/locale/bs').then(loc => loc.bs),
	'br-FR': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'ca-ES': import('date-fns/locale/ca').then(loc => loc.ca),
	'cs-CZ': import('date-fns/locale/cs').then(loc => loc.cs),
	'co-FR': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'cy-GB': import('date-fns/locale/cy').then(loc => loc.cy),
	'da-DK': import('date-fns/locale/da').then(loc => loc.da),
	'de-DE': import('date-fns/locale/de').then(loc => loc.de),
	'de-AT': import('date-fns/locale/de-AT').then(loc => loc.deAT),
	'de-CH': import('date-fns/locale/de').then(loc => loc.de),
	'et-EE': import('date-fns/locale/et').then(loc => loc.et),
	'en-AU': import('date-fns/locale/en-AU').then(loc => loc.enAU),
	'en-CA': import('date-fns/locale/en-CA').then(loc => loc.enCA),
	'en-IN': import('date-fns/locale/en-IN').then(loc => loc.enIN),
	'en-IE': import('date-fns/locale/en-IE').then(loc => loc.enIE),
	'en-NZ': import('date-fns/locale/en-NZ').then(loc => loc.enNZ),
	'en-SG': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'en-ZA': import('date-fns/locale/en-ZA').then(loc => loc.enZA),
	'en-GB': import('date-fns/locale/en-GB').then(loc => loc.enGB),
	'en-US': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'es-ES': import('date-fns/locale/es').then(loc => loc.es),
	'eu-ES': import('date-fns/locale/eu').then(loc => loc.eu),
	'ee-GH': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'fo-FO': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'fr-CA': import('date-fns/locale/fr-CA').then(loc => loc.frCA),
	'fr-FR': import('date-fns/locale/fr').then(loc => loc.fr),
	'fy-NL': import('date-fns/locale/fy').then(loc => loc.fy),
	'ga-IE': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'gd-GB': import('date-fns/locale/gd').then(loc => loc.gd),
	'gl-ES': import('date-fns/locale/gl').then(loc => loc.gl),
	'ha-NG': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'hr-HR': import('date-fns/locale/hr').then(loc => loc.hr),
	'ig-NG': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'xh-ZA': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'zu-ZA': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'is-IS': import('date-fns/locale/is').then(loc => loc.is),
	'it-IT': import('date-fns/locale/it').then(loc => loc.it),
	'jv-ID': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'rw-RW': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'sw-TZ': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'ht-HT': import('date-fns/locale/ht').then(loc => loc.ht),
	'lv-LV': import('date-fns/locale/lv').then(loc => loc.lv),
	'lt-LT': import('date-fns/locale/lt').then(loc => loc.lt),
	'ln-CD': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'lg-UG': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'hu-HU': import('date-fns/locale/hu').then(loc => loc.hu),
	'mg-MG': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'mt-MT': import('date-fns/locale/mt').then(loc => loc.mt),
	'mi-NZ': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'nl-NL': import('date-fns/locale/nl').then(loc => loc.nl),
	'no-NO': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'uz-UZ': import('date-fns/locale/uz').then(loc => loc.uz),
	'pl-PL': import('date-fns/locale/pl').then(loc => loc.pl),
	'pt-BR': import('date-fns/locale/pt-BR').then(loc => loc.ptBR),
	'pt-PT': import('date-fns/locale/pt').then(loc => loc.pt),
	'ro-RO': import('date-fns/locale/ro').then(loc => loc.ro),
	'rm-CH': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'st-ZA': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'tn-BW': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'sq-AL': import('date-fns/locale/sq').then(loc => loc.sq),
	'sk-SK': import('date-fns/locale/sk').then(loc => loc.sk),
	'sl-SI': import('date-fns/locale/sl').then(loc => loc.sl),
	'so-SO': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'fi-FI': import('date-fns/locale/fi').then(loc => loc.fi),
	'sv-SE': import('date-fns/locale/sv').then(loc => loc.sv),
	'tl-PH': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'vi-VN': import('date-fns/locale/vi').then(loc => loc.vi),
	'tr-TR': import('date-fns/locale/tr').then(loc => loc.tr),
	'tk-TM': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'wo-SN': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'yo-NG': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'el-GR': import('date-fns/locale/el').then(loc => loc.el),
	'be-BY': import('date-fns/locale/be').then(loc => loc.be),
	'bg-BG': import('date-fns/locale/bg').then(loc => loc.bg),
	'ky-KG': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'kk-KZ': import('date-fns/locale/kk').then(loc => loc.kk),
	'mk-MK': import('date-fns/locale/mk').then(loc => loc.mk),
	'mn-MN': import('date-fns/locale/mn').then(loc => loc.mn),
	'ru-RU': import('date-fns/locale/ru').then(loc => loc.ru),
	'sr-RS': import('date-fns/locale/sr').then(loc => loc.sr),
	'tt-RU': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'tg-TJ': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'uk-UA': import('date-fns/locale/uk').then(loc => loc.uk),
	'ka-GE': import('date-fns/locale/ka').then(loc => loc.ka),
	'hy-AM': import('date-fns/locale/hy').then(loc => loc.hy),
	'he-IL': import('date-fns/locale/he').then(loc => loc.he),
	'ug-CN': import('date-fns/locale/ug').then(loc => loc.ug),
	'ur-PK': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'ar-SA': import('date-fns/locale/ar-SA').then(loc => loc.arSA),
	'ps-AF': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'sd-IN': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'fa-IR': import('date-fns/locale/fa-IR').then(loc => loc.faIR),
	'ti-ER': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'am-ET': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'ne-NP': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'mr-IN': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'hi-IN': import('date-fns/locale/hi').then(loc => loc.hi),
	'bn-BD': import('date-fns/locale/bn').then(loc => loc.bn),
	'bn-IN': import('date-fns/locale/bn').then(loc => loc.bn),
	'pa-IN': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'gu-IN': import('date-fns/locale/gu').then(loc => loc.gu),
	'or-IN': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'ta-IN': import('date-fns/locale/ta').then(loc => loc.ta),
	'te-IN': import('date-fns/locale/te').then(loc => loc.te),
	'kn-IN': import('date-fns/locale/kn').then(loc => loc.kn),
	'ml-IN': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'si-LK': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'th-TH': import('date-fns/locale/th').then(loc => loc.th),
	'lo-LA': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'my-MM': import('date-fns/locale/en-US').then(loc => loc.enUS),
	'km-KH': import('date-fns/locale/km').then(loc => loc.km),
	'ko-KR': import('date-fns/locale/ko').then(loc => loc.ko),
	'zh-CN': import('date-fns/locale/zh-CN').then(loc => loc.zhCN),
	'zh-TW': import('date-fns/locale/zh-TW').then(loc => loc.zhTW),
	'ja-JP': import('date-fns/locale/ja').then(loc => loc.ja)
}
