import { useEffect } from 'react'

// Zustand
import useUserStore from '../../../store/userStore'

// React Router
import { useNavigate } from 'react-router-dom'

// Shared
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Utils
import { deleteCookie } from '../../../ruya-shared/shared/utils/cookieHelper'

const Logout = () => {
	// Zustand
	const userStore = useUserStore()

	// Router
	const navigate = useNavigate()

	useEffect(() => {
		// Delete token from cookie
		deleteCookie(commonSettings.apps.api.tokenName, { domain: commonSettings.apps.api.cookieOptions.domain })

		// Reset user state
		userStore.reset()

		// Navigate user to login page
		navigate(commonSettings.apps.web.paths.login, { replace: true })
	}, [])

	return null
}

export default Logout
