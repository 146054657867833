import './Journal.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Molecules
import Timeline from '../../organisms/timeline/Timeline'

// Template
import Private from '../../templates/private/Private'

// Router
import { useNavigate } from 'react-router-dom'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Store
import useJournalStore from '../../../store/journalStore'

const Journal = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	return (
		<ContentWrapper className="Journal">
			<H1 isCentered={true}>{t('journal:header')}</H1>

			{!journalStore.loading && !journalStore.error && journalStore.sleepSessionsData.list.length < 1 && (
				<div className="Journal_NoRecords">
					<div className="Journal_NoRecords_Step">
						<MaterialSymbol className="Journal_NoRecords_Icon" name="bedtime" fill="1" />
						<P size="md-bold">{t('journal:newSleepSessionHeader')}</P>
						<P>{t('journal:newSleepSessionDesc')}</P>
					</div>
					<div className="Journal_NoRecords_Separator">
						<div className="Journal_NoRecords_Mobile">
							<MaterialSymbol name="keyboard_arrow_down" />
						</div>
						<div className="Journal_NoRecords_Desktop">
							<MaterialSymbol name="keyboard_arrow_right" />
						</div>
					</div>
					<div className="Journal_NoRecords_Step">
						<MaterialSymbol className="Journal_NoRecords_Icon" name="cloud" fill="1" />
						<P size="md-bold">{t('journal:newDreamHeader')}</P>
						<P>{t('journal:newDreamDesc')}</P>
					</div>
					<div className="Journal_NoRecords_Separator">
						<div className="Journal_NoRecords_Mobile">
							<MaterialSymbol name="keyboard_arrow_down" />
						</div>
						<div className="Journal_NoRecords_Desktop">
							<MaterialSymbol name="keyboard_arrow_right" />
						</div>
					</div>
					<div className="Journal_NoRecords_Step">
						<MaterialSymbol className="Journal_NoRecords_Icon" name="chat_bubble" fill="1" />
						<P size="md-bold">{t('journal:newInterpretHeader')}</P>
						<P>{t('journal:newInterpretDesc')}</P>
					</div>
				</div>
			)}

			<div className="Journal_New">
				<Button
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/session/add`)}
					icon="add"
					text={t('button:createNewSession')}
				/>
			</div>

			<Timeline />
		</ContentWrapper>
	)
}

export default Private(Journal)
