'use client'
import { forwardRef, ForwardedRef, type InputHTMLAttributes } from 'react'
import './Checkbox.scss'
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string
	name: string
	className?: string
	[key: string]: any
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
	const { label, name, className, ...rest } = props

	return (
		<div className={`Checkbox ${className !== undefined ? className : ''}`}>
			<input ref={ref} className="Checkbox_Input" type="checkbox" name={name} id={name} {...rest} />
			{label && (
				<label className="Checkbox_Label" htmlFor={name}>
					<MaterialSymbol name="check_box_outline_blank" fill="1" className="Checkbox_Icon Checkbox_Icon--unchecked" />
					<MaterialSymbol name="check_box" fill="1" className="Checkbox_Icon Checkbox_Icon--checked" />
					{label}
				</label>
			)}
		</div>
	)
})

Checkbox.displayName = 'Checkbox'
export default Checkbox
