import { useEffect, useState } from 'react'
import './DreamViewInterpretation.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import StackButtons from '../../../ruya-shared/shared/ui/atoms/stackButtons/StackButtons'
import { FormFieldSet } from '../../../ruya-shared/shared/ui/atoms/form/Form'

// Store
import useJournalStore from '../../../store/journalStore'
import useInterpreterStore from '../../../store/interpreterStore'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Types
import type { IDream, IDreamQA, IInterpretation, IInterpreter } from '../../../ruya-shared/shared/types'

const DreamViewInterpretation = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId, dreamId, interpretationId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const interpreterStore = useInterpreterStore()

	// Local State
	const [interpretation, setInterpretation] = useState<IInterpretation | null>(null)
	// const [interpreter, setInterpreter] = useState<IInterpreter | null>(null)
	const [currentTab, setCurrentTab] = useState('interpretation')
	const [error, setError] = useState<string | null>(null)

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (sleepSessionId) {
			journalStore.loadSleepSession(sleepSessionId)
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}, [sleepSessionId])

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (journalStore.currentSleepSession !== null && dreamId) {
			const requestedDream = journalStore.currentSleepSession?.dreams.find((dream: IDream) => dream._id === dreamId) || null

			if (requestedDream !== null) {
				// Load interpretation if provided
				if (interpretationId) {
					// Find the requested interpretation by its ID
					const tmpInterpretation =
						requestedDream.interpretations.find(interpretation => interpretation._id === interpretationId) || null

					if (tmpInterpretation !== null) {
						setInterpretation(tmpInterpretation)

						// const tmpInterpreter = interpreterStore.getInterpreter(tmpInterpretation.interpreterId.toString())

						// if (tmpInterpreter) {
						// 	setInterpreter(tmpInterpreter)
						// }
					}
				}
			}
		}
	}, [journalStore.currentSleepSession, dreamId])

	const switchTabs = (tab: string) => {
		// Set the current tab
		setCurrentTab(tab)
	}

	const handleDelete = async () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteInterpretation'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						const response = await journalStore.deleteInterpretation(sleepSessionId, dreamId, interpretationId)

						if (response) {
							navigate(`${commonSettings.apps.web.paths.journal}/dream/${sleepSessionId}/${dreamId}`)
						} else {
							setError(journalStore.error)
						}
					}
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	return (
		<ContentWrapper className="DreamViewInterpretation">
			<div className="DreamViewInterpretation_Content">
				<div className="DreamViewInterpretation_Content_TopBar">
					<HistoryBackLink href={`${commonSettings.apps.web.paths.journal}/dream/${sleepSessionId}/${dreamId}`} />
				</div>

				<H1 className="DreamViewInterpretation_Content_Title">{interpretation?.interpreter.name}</H1>

				<div className="DreamViewInterpretation_Content_Tabs">
					<StackButtons>
						<Button
							size="sm"
							iconFill="1"
							color="light"
							text={t('button:interpretationTab')}
							onClick={() => switchTabs('interpretation')}
							active={currentTab === 'interpretation'}
						/>
						<Button
							size="sm"
							iconFill="1"
							color="light"
							text={t('button:conversationHistoryTab')}
							onClick={() => switchTabs('conversation')}
							active={currentTab === 'conversation'}
						/>
						<Button
							size="sm"
							iconFill="1"
							color="light"
							text={t('button:interpretedDreamTab')}
							onClick={() => switchTabs('dream')}
							active={currentTab === 'dream'}
						/>
					</StackButtons>
				</div>

				{currentTab === 'interpretation' && (
					<div className="DreamViewInterpretation_Content_Interpretation">{interpretation?.interpretation}</div>
				)}

				{currentTab === 'conversation' && (
					<div className="DreamViewInterpretation_Content_DreamQA">
						{interpretation?.dreamQA.map((qa: IDreamQA, index: number) => {
							// Skip if the message is '-'
							if (qa.message === '-') return null

							return (
								<div
									key={index}
									className={`DreamViewInterpretation_Content_DreamQA_Item DreamViewInterpretation_Content_DreamQA_Item--${qa.sender}`}>
									{qa.sender !== 'user' && <MaterialSymbol name={interpretation?.interpreter.icon} fill="1" />}
									<div className="DreamViewInterpretation_Content_DreamQA_Item_Message">
										{qa.message === '-' ? t('journal:skippedAnswer') : qa.message}
									</div>
									{qa.sender === 'user' && <MaterialSymbol name="person" fill="1" />}
								</div>
							)
						})}
					</div>
				)}

				{currentTab === 'dream' && <div className="DreamViewInterpretation_Content_Dream">{interpretation?.dream}</div>}

				<FormFieldSet legend={t('dialog:warningTitle')} className="DreamEdit_Delete">
					<P>{t('journal:deleteDreamWarning')}</P>
					<Button
						disabled={journalStore.loading}
						loading={journalStore.loading}
						text={t('button:delete')}
						icon="delete"
						onClick={handleDelete}
					/>
					{error && <p className="DreamViewInterpretation_Error">{error}</p>}
				</FormFieldSet>
			</div>
		</ContentWrapper>
	)
}

export default Private(DreamViewInterpretation)
