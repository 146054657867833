import type { HTMLAttributes, ReactNode, FormHTMLAttributes } from 'react'
import './Form.scss'

// Utils
import { generateRandomLetters } from '../../../utils/commonHelper'

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
	className?: string
	children: ReactNode
	name?: string
}

// Simple form wrapper
export const Form = (props: FormProps) => {
	const { className, name = generateRandomLetters(), ...rest } = props

	// Classes
	const classNames = 'Form' + `${className !== undefined ? ' ' + className : ''}`

	return (
		<form className={classNames} id={name} name={name} {...rest}>
			{props.children}
		</form>
	)
}

interface FormGroupProps extends HTMLAttributes<HTMLDivElement> {
	twoColumn?: boolean
	threeColumn?: boolean
	fourColumn?: boolean
	fiveColumn?: boolean
	visible?: boolean
	children: ReactNode
}

// Form group
export const FormGroup = (props: FormGroupProps) => {
	const { className, twoColumn, threeColumn, fourColumn, fiveColumn, visible = true, ...rest } = props

	// Classes
	const classNames =
		'FormGroup' +
		`${twoColumn ? ' FormGroup--twoColumn' : ''}` +
		`${threeColumn ? ' FormGroup--threeColumn' : ''}` +
		`${fourColumn ? ' FormGroup--fourColumn' : ''}` +
		`${fiveColumn ? ' FormGroup--fiveColumn' : ''}` +
		`${visible ? '' : 'FormGroup--hidden'}` +
		`${className !== undefined ? ' ' + className : ''}`

	return (
		<div className={classNames} {...rest}>
			{props.children}
		</div>
	)
}

interface FormFieldProps extends HTMLAttributes<HTMLFieldSetElement> {
	className?: string
	legend?: string
	children: ReactNode
}

// Form group label
export const FormFieldSet = (props: FormFieldProps) => {
	const { className, legend, children, ...rest } = props

	// Classes
	const classNames = 'FormFieldSet' + `${className !== undefined ? ' ' + className : ''}`

	return (
		<fieldset className={classNames} {...rest}>
			{legend && <legend>{legend}</legend>}
			{children}
		</fieldset>
	)
}
