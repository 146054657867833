import './SleepSessionsAdd.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { Form } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import DateSelector, { CustomTimeSelector } from '../../../ruya-shared/shared/ui/atoms/dateSelector/DateSelector'
import RangeSlider from '../../../ruya-shared/shared/ui/atoms/rangeSlider/RangeSlider'
import SleepQualitySelector from '../../../ruya-shared/shared/ui/atoms/sleepQualitySelector/SleepQualitySelector'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Store
import useJournalStore from '../../../store/journalStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { sleepSessionSchema } from '../../../validation/dreamSchema'
import { DevTool } from '@hookform/devtools'

// Utils
import { getCurrentDateWithTime } from '../../../ruya-shared/shared/utils/dateHelper'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const SleepSessionsAdd = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	// Default values
	const defaultSleepDuration = 8
	const defaultSleepStart = getCurrentDateWithTime()
	defaultSleepStart.setDate(defaultSleepStart.getDate() - 1)
	defaultSleepStart.setHours(defaultSleepStart.getHours() - defaultSleepDuration)

	const initialValues = {
		sleepStart: defaultSleepStart,
		sleepQuality: 3,
		sleepDuration: defaultSleepDuration
	}

	const formOptions = {
		mode: 'onChange' as const,
		resolver: yupResolver(sleepSessionSchema),
		defaultValues: initialValues
	}
	const {
		handleSubmit,
		control,
		watch,
		formState: { errors, isSubmitting }
	} = useForm(formOptions) // Form
	const sleepDuration = watch('sleepDuration') // Watch sleep duration
	const sleepDurationLabel = t('form:sleepDuration:hours', { count: sleepDuration })

	// Form submit
	const onSubmit = async (values: any) => {
		// Destruct values
		const { sleepStart, sleepDuration, sleepQuality } = values

		// Add dream
		const sessionId = await journalStore.addSleepSession(sleepStart, sleepDuration, sleepQuality)

		// Redirect to view dream
		if (sessionId) {
			navigate('/journal')
		}
	}

	return (
		<ContentWrapper className="SleepSessionsAdd">
			<H1 isCentered={true}>{t('journal:newSleepSessionHeader')}</H1>

			<Form className="SleepSessionsAdd_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<Controller
					control={control}
					name="sleepStart"
					render={({ field }) => {
						const { value, onChange, ref, ...rest } = field
						return (
							<DateSelector
								icon="clear_night"
								customInput={<CustomTimeSelector className="SleepSessionsAdd_Date" />}
								showTimeSelect
								dateFormat="Pp"
								timeFormat="p"
								timeIntervals={60}
								innerRef={ref}
								label={t('form:sleepStart.label')}
								autoComplete="off"
								maxDate={new Date()}
								invalid={Boolean(errors?.sleepStart?.message)}
								invalidMessage={errors?.sleepStart?.message}
								selected={value}
								onChange={date => onChange(date)}
								{...rest}
							/>
						)
					}}
				/>

				<Controller
					control={control}
					name="sleepDuration"
					render={({ field }) => {
						const { value, onChange, ref, ...rest } = field
						return (
							<RangeSlider
								icon="schedule"
								label={t('form:sleepDuration.label')}
								valueLabel={sleepDurationLabel}
								min={1}
								max={12}
								step={1}
								value={value}
								onChange={val => onChange(val)}
								ref={ref}
								{...rest}
							/>
						)
					}}
				/>

				<Controller
					control={control}
					name="sleepQuality"
					render={({ field }) => {
						const { value, onChange, ref, ...rest } = field
						return (
							<SleepQualitySelector
								icon="star"
								value={value}
								label={t('form:sleepQuality.label')}
								onChange={val => onChange(val)}
								{...rest}
							/>
						)
					}}
				/>

				{journalStore.error && (
					<P className="SleepSessionsAdd_Error">
						<MaterialSymbol name="error" /> {journalStore.error}
					</P>
				)}

				<div className="SleepSessionsAdd_Buttons">
					<Button
						type="button"
						color="light"
						text={t('button:cancel')}
						disabled={isSubmitting || journalStore.loading}
						onClick={() => navigate(commonSettings.apps.web.paths.journal)}
					/>
					<Button
						type="submit"
						disabled={isSubmitting || journalStore.loading}
						loading={isSubmitting || journalStore.loading}
						loadingText={t('button:saving')}
						text={t('button:add')}
					/>
				</div>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(SleepSessionsAdd)
