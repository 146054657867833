import { useEffect, useState } from 'react'
import './Subscriptions.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Template
import Private from '../../templates/private/Private'

// Atoms
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'

// Settings
import { subscriptionPackages } from '../../../ruya-shared/shared/config/subscriptionSettings'

// Types
import type { ISubscriptionPackage } from '../../../ruya-shared/shared/types'


const Subscriptions = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Subscription Packages
	const availableSubscriptions = subscriptionPackages.filter((subscription: ISubscriptionPackage) => subscription.isVisible)
	const freeSubscription = availableSubscriptions.find((subscription: ISubscriptionPackage) => subscription.name === 'Free')
	const monthlySubscriptions = availableSubscriptions.filter(
		(subscription: ISubscriptionPackage) => subscription.billingCycle === 'Monthly'
	)
	const yearlySubscriptions = availableSubscriptions.filter(
		(subscription: ISubscriptionPackage) => subscription.billingCycle === 'Yearly'
	)

	const [selectedBillingCycle, setSelectedBillingCycle] = useState<'Monthly' | 'Yearly'>('Monthly')
	const [selectedSubscriptions, setSelectedSubscriptions] = useState<ISubscriptionPackage[] | []>(monthlySubscriptions)
	const [selectedSubscriptionPackage, setSelectedSubscriptionPackage] = useState<ISubscriptionPackage | null>(null)

	useEffect(() => {
		if (selectedBillingCycle === 'Monthly') {
			setSelectedSubscriptions(monthlySubscriptions)
			setSelectedSubscriptionPackage(
				monthlySubscriptions.find((subscription: ISubscriptionPackage) => subscription.name === 'Basic') || monthlySubscriptions[0]
			)
		} else {
			setSelectedSubscriptions(yearlySubscriptions)
			setSelectedSubscriptionPackage(
				yearlySubscriptions.find((subscription: ISubscriptionPackage) => subscription.name === 'Basic') || yearlySubscriptions[0]
			)
		}
	}, [selectedBillingCycle])

	return (
		<ContentWrapper className="Subscriptions">
			<H1 isCentered={true}>{t('subscriptions:header')}</H1>

			<div className="Subscriptions_BillingCycle">
				<button
					type="button"
					className={selectedBillingCycle === 'Monthly' ? 'active' : ''}
					onClick={() => setSelectedBillingCycle('Monthly')}>
					{t('subscriptions:monthly')}
				</button>

				<button
					type="button"
					className={selectedBillingCycle === 'Yearly' ? 'active' : ''}
					onClick={() => setSelectedBillingCycle('Yearly')}>
					{t('subscriptions:yearly')}
				</button>
			</div>

			<div className="Subscriptions_Selections">
				<Package
					key={freeSubscription?.id}
					subscription={freeSubscription || null}
					selectedSubscriptionPackage={selectedSubscriptionPackage}
					setSelectedSubscriptionPackage={setSelectedSubscriptionPackage}
				/>
				{selectedSubscriptions.map((subscription: ISubscriptionPackage) => (
					<Package
						key={subscription.id}
						subscription={subscription}
						selectedSubscriptionPackage={selectedSubscriptionPackage}
						setSelectedSubscriptionPackage={setSelectedSubscriptionPackage}
					/>
				))}
			</div>
		</ContentWrapper>
	)
}

interface ISubscriptionPackageProps {
	subscription: ISubscriptionPackage | null
	selectedSubscriptionPackage: ISubscriptionPackage | null
	setSelectedSubscriptionPackage: (subscription: ISubscriptionPackage) => void
}

const Package = (props: ISubscriptionPackageProps) => {
	const { subscription, selectedSubscriptionPackage, setSelectedSubscriptionPackage } = props

	// Translation Hook
	const { t } = useTranslation()

	if (!subscription) {
		return null
	}

	return (
		<div
			className={`Subscriptions_Package ${
				selectedSubscriptionPackage?.id === subscription?.id ? ' Subscriptions_Package--selected' : ''
			}`}
			onClick={() => setSelectedSubscriptionPackage(subscription)}>
			<H2>{subscription.name}</H2>
			<p>{subscription.description}</p>
			<PackagePrice subscription={subscription} />
			<ul>
				{subscription.services.map((service, index) => (
					<li key={subscription?.id + index} className="Subscriptions_Package_Details">
						<div>
							<p>{service.name}</p>
							<p>{service.description}</p>
						</div>
					</li>
				))}
			</ul>
			<div className="Subscriptions_Package_ButtonContainer">
				<button type="button">{t('subscriptions:subscribe')}</button>
			</div>
		</div>
	)
}

interface IPackagePrice {
	subscription: ISubscriptionPackage
}

const PackagePrice = (props: IPackagePrice) => {
	// Check subscription has discount
	const { subscription } = props

	if (subscription.discount > 0) {
		return (
			<div className="Subscriptions_Package_Price Subscriptions_Package_Price--discount">
				<p>Discount: %{subscription.discount}</p>
				<div className="Subscriptions_Package_Discount">
					<p>{subscription.price}</p>
					<p>{subscription.price - (subscription.price * subscription.discount) / 100}</p>
				</div>
			</div>
		)
	}
	if (subscription.price === 0) {
		return <p className="Subscriptions_Package_Price">Free</p>
	}
	return <p className="Subscriptions_Package_Price">{subscription.price}</p>
}

export default Private(Subscriptions)
