import React, { useEffect, useState } from 'react'
import './DreamView.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import H3 from '../../../ruya-shared/shared/ui/atoms/h3/H3'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Store
import useJournalStore from '../../../store/journalStore'
import useInterpreterStore from '../../../store/interpreterStore'
import useLanguageStore from '../../../store/languageStore'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Types
import type { IDream, IInterpretation, ObjectId } from '../../../ruya-shared/shared/types'

// Utils
import { DateTime } from 'luxon'
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'

const DreamView = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId, dreamId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const interpreterStore = useInterpreterStore()
	const languageStore = useLanguageStore()

	// Local State
	const [dream, setDream] = useState<IDream | null>(null)

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (sleepSessionId) {
			journalStore.loadSleepSession(sleepSessionId)
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}, [sleepSessionId])

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (journalStore.currentSleepSession !== null && dreamId) {
			const requestedDream = journalStore.currentSleepSession?.dreams.find((dream: IDream) => dream._id === dreamId) || null

			if (requestedDream !== null) {
				setDream(requestedDream)
			}
		}
	}, [journalStore.currentSleepSession, dreamId])

	const getInterpreter = (interpreterId: ObjectId) => {
		return interpreterStore?.interpreterList?.find((interpreter: any) => interpreter._id === interpreterId)
	}

	const goToInterpretation = (interpretation: IInterpretation) => {
		if (interpretation.isDreamQACompleted) {
			navigate(`/journal/dream/interpretation/${sleepSessionId}/${dreamId}/${interpretation._id?.toString()}`)
		} else {
			navigate(`/journal/dream-questionnaire/${sleepSessionId}/${dreamId}/${interpretation.interpreter.id}`)
		}
	}

	return (
		<ContentWrapper className="DreamView">
			<div className="DreamView_Content">
				<div className="DreamView_Content_TopBar">
					<HistoryBackLink href={commonSettings.apps.web.paths.journal} />

					<Button
						color="light"
						size="sm"
						icon="edit"
						iconFill="1"
						onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/edit/${sleepSessionId}/${dreamId}`)}
						text={t('button:edit')}
					/>
				</div>

				<H1 className="DreamView_Content_Title">{dream?.title}</H1>

				<P className="DreamView_Content_Date">{dream?.createdAt && languageStore.selectedLanguage?.isoCode && formatDisplayDate(languageStore.selectedLanguage?.isoCode, dream?.createdAt, DateTime.DATE_MED_WITH_WEEKDAY)}</P>

				<div className="DreamView_Content_DreamInfo">
					{dream?.isLucidDream && (
						<span>
							<MaterialSymbol name="self_improvement" /> {t('journal:lucidDream')}
						</span>
					)}
					{dream?.isColor && (
						<span>
							<MaterialSymbol name="palette" /> {t('journal:colorDream')}
						</span>
					)}
					{dream?.isRecurring && (
						<span>
							<MaterialSymbol name="sync" /> {t('journal:recurringDream')}
						</span>
					)}
				</div>

				<div className="DreamView_Content_Dream">{dream?.dream}</div>

				<div className="DreamView_Interpretations">
					<H3>
						{t('journal:interpretationsSubHeader')} ({dream?.interpretations?.length})
					</H3>

					<Button
						text={(dream?.interpretations ?? []).length > 0 ? t('button:addInterpretation') : t('button:startInterpreting')}
						color="light"
						icon="add"
						onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/select-interpreter/${sleepSessionId}/${dreamId}`)}
					/>

					<div className="DreamView_Interpretations_List">
						{dream?.interpretations?.map((interpretation: IInterpretation) => {

							return (
								<div
									key={interpretation._id?.toString()}
									className="DreamView_Interpretations_List_Item"
									onClick={() => goToInterpretation(interpretation)}>
									<MaterialSymbol name={interpretation.interpreter.icon} fill="1" />
									<span className="DreamView_Interpretations_List_Item_Name">{interpretation.interpreter.name}</span>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</ContentWrapper>
	)
}

export default Private(DreamView)
