import { useEffect } from 'react'
import './SleepSessionsEdit.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import { Form, FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import DateSelector, { CustomTimeSelector } from '../../../ruya-shared/shared/ui/atoms/dateSelector/DateSelector'
import RangeSlider from '../../../ruya-shared/shared/ui/atoms/rangeSlider/RangeSlider'
import SleepQualitySelector from '../../../ruya-shared/shared/ui/atoms/sleepQualitySelector/SleepQualitySelector'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Store
import useJournalStore from '../../../store/journalStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { sleepSessionUpdateSchema } from '../../../validation/dreamSchema'
import { DevTool } from '@hookform/devtools'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Utils
import { parseDate } from '../../../ruya-shared/shared/utils/dateHelper'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const SleepSessionsEdit = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	// Load dream if id is provided
	useEffect(() => {
		if (sleepSessionId) {
			journalStore.loadSleepSession(sleepSessionId)
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}, [sleepSessionId])

	// React Hook Form
	const formOptions = {
		mode: 'onChange' as const,
		resolver: yupResolver(sleepSessionUpdateSchema)
	}
	const {
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm(formOptions) // Form
	const sleepDuration = watch('sleepDuration') // Watch sleep duration
	const sleepDurationLabel = t('form:sleepDuration:hours', { count: sleepDuration })

	// Set form values
	useEffect(() => {
		if (journalStore.currentSleepSession !== null) {
			reset({
				// Set form values
				sleepSessionId: journalStore.currentSleepSession._id as string,
				sleepStart: parseDate(journalStore.currentSleepSession?.sleepStart || '', 'js-date'),
				sleepQuality: journalStore.currentSleepSession.sleepQuality,
				sleepDuration: journalStore.currentSleepSession.sleepDuration
			})
		}
	}, [journalStore.currentSleepSession])

	// Form submit
	const onSubmit = async (values: any) => {
		if (sleepSessionId === undefined) return

		// Destruct values
		const { sleepStart, sleepDuration, sleepQuality } = values

		// Add dream
		const isUpdated = await journalStore.updateSleepSession(sleepSessionId, sleepStart, sleepDuration, sleepQuality)

		// Redirect to journal
		if (isUpdated) {
			navigate('/journal')
		}
	}

	// Cancel dream handler
	const handleCancel = () => {
		// Show confirmation dialog if form is modified
		if (isDirty) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:cancelDreamEdit'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => navigate('/journal')
					},
					{
						label: t('button:no')
					}
				]
			})
		} else {
			navigate('/journal')
		}
	}

	// Delete dream handler
	const handleDelete = () => {
		if (sleepSessionId === undefined) return

		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteSleepSession'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						const response = await journalStore.deleteSleepSession(sleepSessionId)

						if (response) {
							navigate('/journal')
						}
					}
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	if (!journalStore.loading && !journalStore.currentSleepSession)
		return (
			<ContentWrapper className="SleepSessionsEdit">
				<P className="SleepSessionsEdit_Error">{t('errors:sleepSessionNotFound')}</P>
			</ContentWrapper>
		)

	return (
		<ContentWrapper className="SleepSessionsEdit">
			<H1 isCentered={true}>{t('journal:editSleepSessionHeader')}</H1>

			<Form className="SleepSessionsEdit_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<Controller
					control={control}
					name="sleepStart"
					render={({ field }) => {
						const { value, onChange, ref, ...rest } = field
						return (
							<DateSelector
								icon="clear_night"
								customInput={<CustomTimeSelector className="SleepSessionsEdit_Date" />}
								showTimeSelect
								dateFormat="Pp"
								timeFormat="p"
								timeIntervals={60}
								innerRef={ref}
								label={t('form:sleepStart.label')}
								autoComplete="off"
								maxDate={new Date()}
								invalid={Boolean(errors?.sleepStart?.message)}
								invalidMessage={errors?.sleepStart?.message}
								selected={value}
								onChange={date => onChange(date)}
								{...rest}
							/>
						)
					}}
				/>

				<Controller
					control={control}
					name="sleepDuration"
					render={({ field }) => {
						const { value, onChange, ref, ...rest } = field
						return (
							<RangeSlider
								icon="schedule"
								label={t('form:sleepDuration.label')}
								valueLabel={sleepDurationLabel}
								min={1}
								max={12}
								step={1}
								value={value}
								onChange={val => onChange(val)}
								ref={ref}
								{...rest}
							/>
						)
					}}
				/>

				<Controller
					control={control}
					name="sleepQuality"
					render={({ field }) => {
						const { value, onChange, ref, ...rest } = field

						return (
							<SleepQualitySelector
								icon="star"
								value={value}
								label={t('form:sleepQuality.label')}
								onChange={val => onChange(val)}
								{...rest}
							/>
						)
					}}
				/>

				{journalStore.error && (
					<P className="SleepSessionsEdit_Error">
						<MaterialSymbol name="error" /> {journalStore.error}
					</P>
				)}

				<div className="SleepSessionsEdit_Buttons">
					<Button
						type="button"
						color="light"
						text={t('button:cancel')}
						disabled={isSubmitting || journalStore.loading}
						onClick={handleCancel}
					/>

					<Button
						type="submit"
						disabled={isSubmitting || journalStore.loading}
						loading={isSubmitting || journalStore.loading}
						loadingText={t('button:saving')}
						text={t('button:saveChanges')}
					/>
				</div>

				<FormFieldSet legend={t('dialog:warningTitle')} className="SleepSessionsEdit_Delete">
					<P>{t('journal:deleteSleepSessionWarning')}</P>
					<Button
						disabled={isSubmitting || journalStore.loading}
						loading={isSubmitting || journalStore.loading}
						text={t('button:delete')}
						icon="delete"
						onClick={handleDelete}
					/>
				</FormFieldSet>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(SleepSessionsEdit)
