import type { ILanguageSettings } from '../types/LanguageTypes'

const languageSettings: ILanguageSettings = {
	cookie: {
		cookieName: 'ruya-lang',
		cookieOptions: {
			expires: 7, // Cookie expires in 7 days
			path: '/', // Accessible for all paths on the domain
			domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.ruya.co', // Accessible across all subdomains or localhost
			secure: true, // Only send the cookie over HTTPS
			httpOnly: false, // Allow client-side scripts to access the cookie since it's just a language setting
			sameSite: 'lax' // Secure enough for your use case and allows normal browsing use
		}
	},
	allowChangeLanguage: true,
	autoDetectLanguage: true,
	autoDetectAlternateLanguages: true,
	defaultLanguage: 'en-GB',
	fallbackLanguage: ['en-GB'],
	showCountryFlag: true,
	matchShortCode: true,
	defaultNS: 'common',
	languages: [
		// Audio generation only enable for languages that exceeded <50% word error rate (WER) which is an industry standard benchmark for speech to text model accuracy.
		{ displayName: 'English (Australia)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (Australia)', isoCode: 'en-AU', flag: 'au.svg', dateFns: 'en-AU', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'English (Canada)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (Canada)', isoCode: 'en-CA', flag: 'ca.svg', dateFns: 'en-CA', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'English (India)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (India)', isoCode: 'en-IN', flag: 'in.svg', dateFns: 'en-IN', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'English (Ireland)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (Ireland)', isoCode: 'en-IE', flag: 'ie.svg', dateFns: 'en-IE', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'English (New Zealand)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (New Zealand)', isoCode: 'en-NZ', flag: 'nz.svg', dateFns: 'en-NZ', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'English (Singapore)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (Singapore)', isoCode: 'en-SG', flag: 'sg.svg', dateFns: 'en-US', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'English (South Africa)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (South Africa)', isoCode: 'en-ZA', flag: 'za.svg', dateFns: 'en-ZA', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'English (UK)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (UK)', isoCode: 'en-GB', flag: 'gb.svg', dateFns: 'en-GB', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'English (US)', defaultScript: 'Latin', subsets:['latin'], englishName: 'English (US)', isoCode: 'en-US', flag: 'us.svg', dateFns: 'en-US', active: true, direction: 'ltr', slogan: 'Vision, Sight.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Italiano', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Italian', isoCode: 'it-IT', flag: 'it.svg', dateFns: 'it', active: true, direction: 'ltr', slogan: 'Visione, Vista.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Afrikaans', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Afrikaans', isoCode: 'af-ZA', flag: 'za.svg', dateFns: 'af', active: true, direction: 'ltr', slogan: 'Visie, Sig', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Español', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Spanish', isoCode: 'es-ES', flag: 'es.svg', dateFns: 'es', active: true, direction: 'ltr', slogan: 'Visión, Vista.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Deutsch (Deutschland)', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'German (Germany)', isoCode: 'de-DE', flag: 'de.svg', dateFns: 'de', active: true, direction: 'ltr', slogan: 'Vision, Sicht.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Deutsch (Österreich)', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'German (Austria)', isoCode: 'de-AT', flag: 'at.svg', dateFns: 'de-AT', active: true, direction: 'ltr', slogan: 'Vision, Sicht.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Deutsch (Schweiz)', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'German (Switzerland)', isoCode: 'de-CH', flag: 'ch.svg', dateFns: 'de', active: true, direction: 'ltr', slogan: 'Vision, Sicht.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Français (Canada)', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'French (Canada)', isoCode: 'fr-CA', flag: 'ca.svg', dateFns: 'fr-CA', active: true, direction: 'ltr', slogan: 'Vision, Vue.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Français (France)', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'French (France)', isoCode: 'fr-FR', flag: 'fr.svg', dateFns: 'fr', active: true, direction: 'ltr', slogan: 'Vision, Vue.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Bahasa Indonesia', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Indonesian', isoCode: 'id-ID', flag: 'id.svg', dateFns: 'id', active: true, direction: 'ltr', slogan: 'Visi, Penglihatan', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Polski', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Polish', isoCode: 'pl-PL', flag: 'pl.svg', dateFns: 'pl', active: true, direction: 'ltr', slogan: 'Wizja, Wzrok', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Українська', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Ukranian', isoCode: 'uk-UA', flag: 'ua.svg', dateFns: 'uk', active: true, direction: 'ltr', slogan: 'Візія, Зір', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Ελληνικά', defaultScript: 'Greek', subsets: ['greek'], englishName: 'Greek', isoCode: 'el-GR', flag: 'gr.svg', dateFns: 'el', active: true, direction: 'ltr', slogan: 'Όραση, Θέαση', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Latviešu', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Latvian', isoCode: 'lv-LV', flag: 'lv.svg', dateFns: 'lv', active: true, direction: 'ltr', slogan: 'Redzējums, Skats', autoTranslate: true, audioGeneration: true },
		{ displayName: '中文 (简体)', defaultScript: 'Simplified', subsets: ['chinese-simplified'], englishName: 'Chinese Simplified', isoCode: 'zh-CN', flag: 'cn.svg', dateFns: 'zh-CN', active: true, direction: 'ltr', slogan: '视觉, 视力', autoTranslate: true, audioGeneration: true },
		{ displayName: '中文 (繁體)', defaultScript: 'Traditional', subsets: ['chinese-traditional'], englishName: 'Chinese Traditional', isoCode: 'zh-TW', flag: 'tw.svg', dateFns: 'zh-TW', active: true, direction: 'ltr', slogan: '视觉, 视力', autoTranslate: true, audioGeneration: true },
		{ displayName: 'العربية', defaultScript: 'Arabic', subsets: ['arabic'], englishName: 'Arabic', isoCode: 'ar-SA', flag: 'sa.svg', dateFns: 'ar-SA', active: true, direction: 'rtl', slogan: 'رؤية, بصيرة', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Türkçe', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'],  englishName: 'Turkish', isoCode: 'tr-TR', flag: 'tr.svg', dateFns: 'tr', active: true, direction: 'ltr', slogan: 'Vizyon, Görüş.', autoTranslate: true, audioGeneration: true },
		{ displayName: '日本語', defaultScript: 'Japanese', subsets: ['japanese'], englishName: 'Japanese', isoCode: 'ja-JP', flag: 'jp.svg', dateFns: 'ja', active: true, direction: 'ltr', slogan: 'ビジョン, 視覚', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Kiswahili', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Swahili', isoCode: 'sw-TZ', flag: 'tz.svg', dateFns: 'en-US', active: true, direction: 'ltr', slogan: 'Maono, Mwono', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Cymraeg', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Welsh', isoCode: 'cy-GB', flag: 'gb.svg', dateFns: 'cy', active: true, direction: 'ltr', slogan: 'Gweledigaeth, Golwg', autoTranslate: true, audioGeneration: true },
		{ displayName: '한국어', defaultScript: 'Hangul', subsets: ['korean'], englishName: 'Korean', isoCode: 'ko-KR', flag: 'kr.svg', dateFns: 'ko', active: true, direction: 'ltr', slogan: '비전, 시력', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Íslenska', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Icelandic', isoCode: 'is-IS', flag: 'is.svg', dateFns: 'is', active: true, direction: 'ltr', slogan: 'Sýn, Sjón', autoTranslate: true, audioGeneration: true },
		{ displayName: 'বাংলা', defaultScript: 'Bengali', subsets: ['bengali'], englishName: 'Bangla', isoCode: 'bn-BD', flag: 'bd.svg', dateFns: 'bn', active: true, direction: 'ltr', slogan: 'দৃষ্টি, দর্শন', autoTranslate: true, audioGeneration: false },
		{ displayName: 'বাংলা', defaultScript: 'Bengali', subsets: ['bengali'], englishName: 'Bengali', isoCode: 'bn-IN', flag: 'in.svg', dateFns: 'bn', active: false, direction: 'ltr', slogan: 'দৃষ্টি, দর্শন', autoTranslate: true, audioGeneration: false },
		{ displayName: 'اردو', defaultScript: 'Arabic', subsets: ['arabic'], englishName: 'Urdu', isoCode: 'ur-PK', flag: 'pk.svg', dateFns: 'en-US', active: true, direction: 'rtl', slogan: 'بینائی, نظر', autoTranslate: true, audioGeneration: true },
		{ displayName: 'नेपाली', defaultScript: 'Devanagari', subsets: ['devanagari'], englishName: 'Nepali', isoCode: 'ne-NP', flag: 'np.svg', dateFns: 'en-US', active: true, direction: 'ltr', slogan: 'दृष्टि, सिघ्र', autoTranslate: true, audioGeneration: true },
		{ displayName: 'ไทย', defaultScript: 'Thai', subsets: ['thai'], englishName: 'Thai', isoCode: 'th-TH', flag: 'th.svg', dateFns: 'th', active: true, direction: 'ltr', slogan: 'วิสัยทัศน์, การมองเห็น', autoTranslate: true, audioGeneration: true },
		{ displayName: 'ਪੰਜਾਬੀ', defaultScript: 'Gurmukhi', subsets: ['gurmukhi'], englishName: 'Punjabi', isoCode: 'pa-IN', flag: 'in.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'ਵਿਜ਼ਨ, ਸਾਈਟ', autoTranslate: true, audioGeneration: false }, // Takes too long to translate
		{ displayName: 'मराठी', defaultScript: 'Devanagari', subsets: ['devanagari'], englishName: 'Marathi', isoCode: 'mr-IN', flag: 'in.svg', dateFns: 'en-US', active: true, direction: 'ltr', slogan: 'दृष्टी, दर्शन', autoTranslate: true, audioGeneration: true },
		{ displayName: 'తెలుగు', defaultScript: 'Telugu', subsets: ['telugu'], englishName: 'Telugu', isoCode: 'te-IN', flag: 'in.svg', dateFns: 'te', active: false, direction: 'ltr', slogan: 'దృష్టి, సైట్', autoTranslate: true, audioGeneration: false }, // Takes too long to translate
		{ displayName: 'Eesti', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Estonian', isoCode: 'et-EE', flag: 'ee.svg', dateFns: 'et', active: true, direction: 'ltr', slogan: 'Visioon, Nägemine', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Bosanski', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Bosnian', isoCode: 'bs-BA', flag: 'ba.svg', dateFns: 'bs', active: true, direction: 'ltr', slogan: 'Vizija, Vid', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Gaeilge', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Irish', isoCode: 'ga-IE', flag: 'ie.svg', dateFns: 'en-US', active: true, direction: 'ltr', slogan: 'Fís, Radharc', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Gàidhlig na h-Alba', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Scottish Gaelic', isoCode: 'gd-GB', flag: 'gb.svg', dateFns: 'gd', active: true, direction: 'ltr', slogan: 'Sealladh, Faic', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Hrvatski', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Croatian', isoCode: 'hr-HR', flag: 'hr.svg', dateFns: 'hr', active: true, direction: 'ltr', slogan: 'Vizija, Vid', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Lietuvių', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Lithuanian', isoCode: 'lt-LT', flag: 'lt.svg', dateFns: 'lt', active: true, direction: 'ltr', slogan: 'Vizija, Regėjimas', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Magyar', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Hungarian', isoCode: 'hu-HU', flag: 'hu.svg', dateFns: 'hu', active: true, direction: 'ltr', slogan: 'Vízió, Látás', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Nederlands', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Dutch', isoCode: 'nl-NL', flag: 'nl.svg', dateFns: 'nl', active: true, direction: 'ltr', slogan: 'Visie, Zicht.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Norsk', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Norwegian', isoCode: 'no-NO', flag: 'no.svg', dateFns: 'en-US', active: true, direction: 'ltr', slogan: 'Visjon, Syn', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Português (Brasil)', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Portuguese (Brazil)', isoCode: 'pt-BR', flag: 'br.svg', dateFns: 'pt-BR', active: true, direction: 'ltr', slogan: 'Visão, Vista.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Português (Portugal)', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Portuguese (Portugal)', isoCode: 'pt-PT', flag: 'pt.svg', dateFns: 'pt', active: true, direction: 'ltr', slogan: 'Visão, Vista.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Беларуская', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Belarusian', isoCode: 'be-BY', flag: 'by.svg', dateFns: 'be', active: true, direction: 'ltr', slogan: 'Візія, Зрок', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Български', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Bulgarian', isoCode: 'bg-BG', flag: 'bg.svg', dateFns: 'bg', active: true, direction: 'ltr', slogan: 'Визия, Зрение', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Русский', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Russian', isoCode: 'ru-RU', flag: 'ru.svg', dateFns: 'ru', active: true, direction: 'ltr', slogan: 'Видение, Зрение', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Српски', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Serbian', isoCode: 'sr-RS', flag: 'rs.svg', dateFns: 'sr', active: true, direction: 'ltr', slogan: 'Вид, Видик', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Soomaali', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Somali', isoCode: 'so-SO', flag: 'so.svg', dateFns: 'en-US', active: true, direction: 'ltr', slogan: 'Aragti, Indho', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Suomi', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Finnish', isoCode: 'fi-FI', flag: 'fi.svg', dateFns: 'fi', active: true, direction: 'ltr', slogan: 'Visio, Näkökyky', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Svenska', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Swedish', isoCode: 'sv-SE', flag: 'se.svg', dateFns: 'sv', active: true, direction: 'ltr', slogan: 'Vision, Syn', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Română', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Romanian', isoCode: 'ro-RO', flag: 'ro.svg', dateFns: 'ro', active: true, direction: 'ltr', slogan: 'Viziune, Vedere', autoTranslate: true, audioGeneration: true },
		// Temporary disabled languages because of the accuracy below %60
		{ displayName: 'Afaan Oromoo', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Oromo', isoCode: 'om-ET', flag: 'et.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: "Mul'ata, Argaa", autoTranslate: true, audioGeneration: false },
		{ displayName: 'Azərbaycanca', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Azerbaijani', isoCode: 'az-AZ', flag: 'az.svg', dateFns: 'az', active: false, direction: 'ltr', slogan: 'Görüş, İrfan.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Bahasa Melayu', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Malay', isoCode: 'ms-MY', flag: 'my.svg', dateFns: 'ms', active: false, direction: 'ltr', slogan: 'Visi, Penglihatan', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Basa Sunda', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Sundanese', isoCode: 'su-ID', flag: 'id.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Visi, Paningal', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Brezhoneg', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Breton', isoCode: 'br-FR', flag: 'fr.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Gwel, Gweled', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Català', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Catalan', isoCode: 'ca-ES', flag: 'es.svg', dateFns: 'ca', active: false, direction: 'ltr', slogan: 'Visió, Vista', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Čeština', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Czech', isoCode: 'cs-CZ', flag: 'cz.svg', dateFns: 'cs', active: false, direction: 'ltr', slogan: 'Vize, Zrak', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Corsu', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Corsican', isoCode: 'co-FR', flag: 'fr.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Visione, Vista', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Dansk', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Danish', isoCode: 'da-DK', flag: 'dk.svg', dateFns: 'da', active: false, direction: 'ltr', slogan: 'Vision, Syn.', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Euskara', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Basque', isoCode: 'eu-ES', flag: 'es.svg', dateFns: 'eu', active: false, direction: 'ltr', slogan: 'Ikuspegi, Ikusmen', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Eʋegbe', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Ewe', isoCode: 'ee-GH', flag: 'gh.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Avizɔ, Gãdodo', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Føroyskt', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Faroese', isoCode: 'fo-FO', flag: 'fo.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Sjón, Sýni', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Frysk', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Western Frisian', isoCode: 'fy-NL', flag: 'nl.svg', dateFns: 'fy', active: false, direction: 'ltr', slogan: 'Fisy, Sicht', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Galego', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Galician', isoCode: 'gl-ES', flag: 'es.svg', dateFns: 'gl', active: false, direction: 'ltr', slogan: 'Visión, Vista', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Hausa', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Hausa', isoCode: 'ha-NG', flag: 'ng.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Gani, Hangen Nesa', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Igbo', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Igbo', isoCode: 'ig-NG', flag: 'ng.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Ọhụụ, Anya', autoTranslate: true, audioGeneration: false },
		{ displayName: 'isiXhosa', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Xhosa', isoCode: 'xh-ZA', flag: 'za.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Umbono, Ukubona', autoTranslate: true, audioGeneration: false },
		{ displayName: 'isiZulu', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Zulu', isoCode: 'zu-ZA', flag: 'za.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Umbono, Ukubuka', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Jawa', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Javanese', isoCode: 'jv-ID', flag: 'id.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Visi, Pandelengan', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Kinyarwanda', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Kinyarwanda', isoCode: 'rw-RW', flag: 'rw.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Igitekerezo, Imboni', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Kreyòl Ayisyen', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Haitian Creole', isoCode: 'ht-HT', flag: 'ht.svg', dateFns: 'ht', active: false, direction: 'ltr', slogan: 'Vizyon, Je', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Lingála', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Lingala', isoCode: 'ln-CD', flag: 'cd.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Eloko Oyo Ozali Kotala, Miso', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Luganda', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Ganda', isoCode: 'lg-UG', flag: 'ug.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Okulaba, Okutunulira', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Malagasy', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Malagasy', isoCode: 'mg-MG', flag: 'mg.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Fahitana, Fijery', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Malti', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Maltese', isoCode: 'mt-MT', flag: 'mt.svg', dateFns: 'mt', active: false, direction: 'ltr', slogan: 'Viżjoni, Vista', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Māori', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Māori', isoCode: 'mi-NZ', flag: 'nz.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Tirohanga, Kitenga', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Oʻzbek', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Uzbek', isoCode: 'uz-UZ', flag: 'uz.svg', dateFns: 'uz', active: false, direction: 'ltr', slogan: "Ko'rish, Qarash", autoTranslate: true, audioGeneration: false },
		{ displayName: 'Rumantsch', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Romansh', isoCode: 'rm-CH', flag: 'ch.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Visiun, Vista', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Sesotho', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Southern Sotho', isoCode: 'st-ZA', flag: 'za.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Pono, Pono', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Setswana', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Tswana', isoCode: 'tn-BW', flag: 'bw.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Pono, Pono', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Shqip', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Albanian', isoCode: 'sq-AL', flag: 'al.svg', dateFns: 'sq', active: false, direction: 'ltr', slogan: 'Vizioni, Shikimi', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Slovenčina', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Slovak', isoCode: 'sk-SK', flag: 'sk.svg', dateFns: 'sk', active: false, direction: 'ltr', slogan: 'Vízia, Zrak', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Slovenščina', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Slovenian', isoCode: 'sl-SI', flag: 'si.svg', dateFns: 'sl', active: false, direction: 'ltr', slogan: 'Vizija, Vid', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Tagalog', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Tagalog', isoCode: 'tl-PH', flag: 'ph.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Paningin, Tanaw', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Tiếng Việt', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Vietnamese', isoCode: 'vi-VN', flag: 'vn.svg', dateFns: 'vi', active: false, direction: 'ltr', slogan: 'Tầm nhìn, Thị giác', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Türkmen', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Turkmen', isoCode: 'tk-TM', flag: 'tm.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Görüş, Göz', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Wolof', defaultScript: 'Latin', subsets: ['latin'], englishName: 'Wolof', isoCode: 'wo-SN', flag: 'sn.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Visi, Gis', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Yorùbá', defaultScript: 'Latin', subsets: ['latin', 'latin-ext'], englishName: 'Yoruba', isoCode: 'yo-NG', flag: 'ng.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Irántí, Ìwò', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Кыргызча', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Kyrgyz', isoCode: 'ky-KG', flag: 'kg.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Көз караш, Көрүү', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Қазақша', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Kazakh', isoCode: 'kk-KZ', flag: 'kz.svg', dateFns: 'kk', active: false, direction: 'ltr', slogan: 'Көру, Көзқарас', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Македонски', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Macedonian', isoCode: 'mk-MK', flag: 'mk.svg', dateFns: 'mk', active: false, direction: 'ltr', slogan: 'Визија, Вид', autoTranslate: true, audioGeneration: true },
		{ displayName: 'Монгол', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Mongolian', isoCode: 'mn-MN', flag: 'mn.svg', dateFns: 'mn', active: false, direction: 'ltr', slogan: 'Хараа, Харц', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Татар', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Tatar', isoCode: 'tt-RU', flag: 'ru.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Күзәтү, Күрү', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Тоҷикӣ', defaultScript: 'Cyrillic', subsets: ['cyrillic'], englishName: 'Tajik', isoCode: 'tg-TJ', flag: 'tj.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'Дидбонӣ, Биноӣ', autoTranslate: true, audioGeneration: false },
		{ displayName: 'ქართული', defaultScript: 'Georgian', subsets: ['georgian'], englishName: 'Georgian', isoCode: 'ka-GE', flag: 'ge.svg', dateFns: 'ka', active: false, direction: 'ltr', slogan: 'ხედვა, თვალთვალი', autoTranslate: true, audioGeneration: false },
		{ displayName: 'Հայերեն', defaultScript: 'Armenian', subsets: ['armenian'], englishName: 'Armenian', isoCode: 'hy-AM', flag: 'am.svg', dateFns: 'hy', active: false, direction: 'ltr', slogan: 'Տեսլական, Դիտում', autoTranslate: true, audioGeneration: true },
		{ displayName: 'עברית', defaultScript: 'Hebrew', subsets: ['hebrew'], englishName: 'Hebrew', isoCode: 'he-IL', flag: 'il.svg', dateFns: 'he', active: false, direction: 'rtl', slogan: 'חזון, ראייה', autoTranslate: true, audioGeneration: true },
		{ displayName: 'ئۇيغۇرچە', defaultScript: 'Arabic', subsets: ['arabic'], englishName: 'Uyghur', isoCode: 'ug-CN', flag: 'cn.svg', dateFns: 'ug', active: false, direction: 'rtl', slogan: 'كۆرۈش, كۆزىتىش', autoTranslate: true, audioGeneration: false },
		{ displayName: 'پښتو', defaultScript: 'Arabic', subsets: ['arabic'], englishName: 'Pashto', isoCode: 'ps-AF', flag: 'af.svg', dateFns: 'en-US', active: false, direction: 'rtl', slogan: 'ليدلوری, سترګې', autoTranslate: true, audioGeneration: false },
		{ displayName: 'سنڌي', defaultScript: 'Arabic', subsets: ['arabic'], englishName: 'Sindhi', isoCode: 'sd-IN', flag: 'in.svg', dateFns: 'en-US', active: false, direction: 'rtl', slogan: 'نظر, بصیرت', autoTranslate: true, audioGeneration: false },
		{ displayName: 'فارسی', defaultScript: 'Arabic', subsets: ['arabic'], englishName: 'Persian', isoCode: 'fa-IR', flag: 'ir.svg', dateFns: 'fa-IR', active: false, direction: 'rtl', slogan: 'بینایی, دید', autoTranslate: true, audioGeneration: true },
		{ displayName: 'ትግርኛ', defaultScript: 'Ethiopic', subsets: ['ethiopic'], englishName: 'Tigrinya', isoCode: 'ti-ER', flag: 'er.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'ራእይ, ልዕሊ', autoTranslate: true, audioGeneration: false },
		{ displayName: 'አማርኛ', defaultScript: 'Ethiopic', subsets: ['ethiopic'], englishName: 'Amharic', isoCode: 'am-ET', flag: 'et.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'ራእይ, ማየት', autoTranslate: true, audioGeneration: false },
		{ displayName: 'हिन्दी', defaultScript: 'Devanagari', subsets: ['devanagari'], englishName: 'Hindi', isoCode: 'hi-IN', flag: 'in.svg', dateFns: 'hi', active: false, direction: 'ltr', slogan: 'दृष्टि, दृश्य', autoTranslate: true, audioGeneration: true },
		{ displayName: 'ગુજરાતી', defaultScript: 'Gujarati', subsets: ['gujarati'], englishName: 'Gujarati', isoCode: 'gu-IN', flag: 'in.svg', dateFns: 'gu', active: false, direction: 'ltr', slogan: 'દ્રષ્ટિ, દર્શન', autoTranslate: true, audioGeneration: false },
		{ displayName: 'ଓଡ଼ିଆ', defaultScript: 'Oriya', subsets: ['oriya'], englishName: 'Odia', isoCode: 'or-IN', flag: 'in.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'ଦୃଷ୍ଟି, ଦର୍ଶନ', autoTranslate: true, audioGeneration: false },
		{ displayName: 'தமிழ்', defaultScript: 'Tamil', subsets: ['tamil'], englishName: 'Tamil', isoCode: 'ta-IN', flag: 'in.svg', dateFns: 'ta', active: false, direction: 'ltr', slogan: 'பார்வை, காட்சி', autoTranslate: true, audioGeneration: true },
		{ displayName: 'ಕನ್ನಡ', defaultScript: 'Kannada', subsets: ['kannada'], englishName: 'Kannada', isoCode: 'kn-IN', flag: 'in.svg', dateFns: 'kn', active: false, direction: 'ltr', slogan: 'ದೃಷ್ಟಿ, ನೋಟ', autoTranslate: true, audioGeneration: true },
		{ displayName: 'മലയാളം', defaultScript: 'Malayalam', subsets: ['malayalam'], englishName: 'Malayalam', isoCode: 'ml-IN', flag: 'in.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'ദർശനം, കാഴ്ച', autoTranslate: true, audioGeneration: true },
		{ displayName: 'සිංහල', defaultScript: 'Sinhala', subsets: ['sinhala'], englishName: 'Sinhala', isoCode: 'si-LK', flag: 'lk.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'දැක්ම, දෘෂ්ටිය', autoTranslate: true, audioGeneration: false },
		{ displayName: 'ລາວ', defaultScript: 'Lao', subsets: ['lao'], englishName: 'Lao', isoCode: 'lo-LA', flag: 'la.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'ວິໄສທັດ, ສະແດງສາຍ', autoTranslate: true, audioGeneration: false },
		{ displayName: 'ဗမာ', defaultScript: 'Burmese', subsets: ['myanmar'], englishName: 'Burmese', isoCode: 'my-MM', flag: 'mm.svg', dateFns: 'en-US', active: false, direction: 'ltr', slogan: 'မြင်ကွင်း, မျက်စိ', autoTranslate: true, audioGeneration: false },
		{ displayName: 'ខ្មែរ', defaultScript: 'Khmer', subsets: ['khmer'], englishName: 'Khmer', isoCode: 'km-KH', flag: 'kh.svg', dateFns: 'km', active: false, direction: 'ltr', slogan: 'ទស្សនៈ, ការមើលឃើញ', autoTranslate: true, audioGeneration: false }
		// { displayName: 'Central Kurdish', isoCode: 'ckb-IQ', flag: 'iq.svg', dateFns:'en-US', active: false, direction: 'rtl', slogan: 'بینین, دیتن', autoTranslate: true, audioGeneration: false }, // 3 letter isoCode
		// { displayName: 'Cherokee', isoCode: 'chr-US', flag: 'us.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'ᎤᏗᏞᎦᏓ, ᏗᎧᏃᎩᏍᏗ', autoTranslate: true, audioGeneration: false }, // 3 letter isoCode
		// { displayName: 'Hawaiian', isoCode: 'haw-US', flag: 'us.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'ʻIke, Nānā', autoTranslate: true, audioGeneration: false }, // 3 letter isoCode
		// { displayName: 'Filipino', isoCode: 'fil-PH', flag: 'ph.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Pananaw, Paningin', autoTranslate: true, audioGeneration: false }, // 3 letter isoCode
		// { displayName: 'Northern Sotho', isoCode: 'nso-ZA', flag: 'za.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Pono, Pono', autoTranslate: true, audioGeneration: false }, // 3 letter isoCode
		// { displayName: 'Seychellois Creole', isoCode: 'crs-SC', flag: 'sc.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vizyon, Vwar', autoTranslate: true, audioGeneration: false }, // 3 letter isoCode
		// // Not in use
		// { displayName: 'Acoli (Uganda)', isoCode: 'ach-UG', flag: 'ug.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Akan', isoCode: '', flag: 'gh.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Balinese', isoCode: '', flag: 'id.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Yiddish', isoCode: 'yi-', flag: '', active: false, direction: 'rtl', slogan: 'וויזיע, זעאונג', autoTranslate: true, audioGeneration: false }, // No Country
		// { displayName: 'Tumbuka', isoCode: '', flag: 'mw.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation, No Country
		// { displayName: 'Twi', isoCode: 'tw-GH', flag: 'gh.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Kongo', isoCode: 'kg-CG', flag: 'cg.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Morisyen', isoCode: 'mfe-MU', flag: 'mu.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Krio', isoCode: 'kri-SL', flag: 'sl.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Tongan', isoCode: 'to-TO', flag: 'to.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Lozi', isoCode: 'loz-ZM', flag: 'zm.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Luba-Lulua', isoCode: 'lua-CD', flag: 'cd.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Guarani', isoCode: 'gn-PY', flag: 'py.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Bemba', isoCode: 'bem-ZM', flag: 'zm.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Rundi', isoCode: 'rn-BI', flag: 'bi.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Interlingua', isoCode: '', flag: 'ia.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Ga', isoCode: 'gaa-GH', flag: 'gh.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Nigerian Pidgin', isoCode: '', flag: 'ng.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Nyanja', isoCode: 'ny-MW', flag: 'mw.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Occitan', isoCode: '', flag: 'fr.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation, No Country
		// { displayName: 'Cebuano', isoCode: '', flag: 'ph.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation, No Country
		// { displayName: 'Shona', isoCode: 'sn-ZW', flag: 'zw.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Quechua', isoCode: 'qu-PE', flag: 'pe.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Nyankole', isoCode: 'nyn-UG', flag: 'ug.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vision, Sight', autoTranslate: true, audioGeneration: false }, // No Translation
		// { displayName: 'Esperanto', isoCode: '', flag: 'eo.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Vizio, Vido', autoTranslate: true, audioGeneration: false }, // No IsoCode
		// { displayName: 'Latin', isoCode: '', flag: 'va.svg', dateFns:'en-US', active: false, direction: 'ltr', slogan: 'Visio, Visus', autoTranslate: true, audioGeneration: false } // No IsoCode
	],
	languageFontMapping: {
		"Latin": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans",
			fallback: "Arial, sans-serif"
		},
		"Cyrillic": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Cyrillic:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Cyrillic",
			fallback: "Arial, sans-serif"
		},
		"Greek": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Greek:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Greek",
			fallback: "Arial, sans-serif"
		},
		"Simplified": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans SC",
			fallback: "PingFang SC, sans-serif"
		},
		"Traditional": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans TC",
			fallback: "PingFang TC, sans-serif"
		},
		"Arabic": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Arabic",
			fallback: "Arial, sans-serif"
		},
		"Japanese": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans JP",
			fallback: "Hiragino Kaku Gothic Pro, sans-serif"
		},
		"Hangul": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans KR",
			fallback: "Malgun Gothic, sans-serif"
		},
		"Bengali": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Bengali",
			fallback: "Arial, sans-serif"
		},
		"Devanagari": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Devanagari",
			fallback: "Arial, sans-serif"
		},
		"Thai": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Thai",
			fallback: "Arial, sans-serif"
		},
		"Gurmukhi": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Gurmukhi:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Gurmukhi",
			fallback: "Arial, sans-serif"
		},
		"Telugu": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Telugu:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Telugu",
			fallback: "Arial, sans-serif"
		},
		"Georgian": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Georgian",
			fallback: "Arial, sans-serif"
		},
		"Armenian": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Armenian",
			fallback: "Arial, sans-serif"
		},
		"Hebrew": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Hebrew",
			fallback: "Arial, sans-serif"
		},
		"Ethiopic": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Ethiopic:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Ethiopic",
			fallback: "Arial, sans-serif"
		},
		"Gujarati": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Gujarati:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Gujarati",
			fallback: "Arial, sans-serif"
		},
		"Oriya": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Oriya:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Oriya",
			fallback: "Arial, sans-serif"
		},
		"Tamil": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Tamil:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Tamil",
			fallback: "Arial, sans-serif"
		},
		"Kannada": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Kannada:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Kannada",
			fallback: "Arial, sans-serif"
		},
		"Malayalam": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Malayalam:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Malayalam",
			fallback: "Arial, sans-serif"
		},
		"Sinhala": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Sinhala:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Sinhala",
			fallback: "Arial, sans-serif"
		},
		"Lao": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Lao",
			fallback: "Arial, sans-serif"
		},
		"Burmese": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Myanmar:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Myanmar",
			fallback: "Arial, sans-serif"
		},
		"Khmer": {
			fontURL: "https://fonts.googleapis.com/css2?family=Noto+Sans+Khmer:wght@400;500;600;700&display=swap",
			fontName: "Noto Sans Khmer",
			fallback: "Arial, sans-serif"
		}
	}	
}

export default languageSettings
