import type { HTMLAttributes, ReactNode } from 'react'
import './Loading.scss'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
	white?: boolean
	className?: string
	children?: ReactNode
}

const Loading = (props: LoadingProps) => {
	const { white, className, ...rest } = props

	// Classes
	const classNames = 'Loading' + `${white ? ' Loading--white' : ' Loading--black'}` + `${!className ? ' ' + className : ''}`

	return (
		<div className={classNames} {...rest}>
			<MaterialSymbol name="progress_activity" className="css-spin" />
		</div>
	)
}

export default Loading
