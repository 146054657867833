import './H4.scss'

interface IH4Props {
	children: React.ReactNode | React.ReactNode[]
	isCentered?: boolean
	className?: string
}

const H4 = (props: IH4Props) => {
	const { children, isCentered, className } = props

	const classNames = 'H4' + `${isCentered ? ' H4--centered' : ''}` + `${className ? ' ' + className : ''}`

	return <h4 className={classNames}>{children}</h4>
}

export default H4
