import './Header.scss'

// Organisms
import Navigation from '../navigation/Navigation'

const Header = () => {
	return (
		<header className="Header">
			<div className="Header_Content">
				<Navigation />
			</div>
		</header>
	)
}

export default Header
