import { useState } from 'react'
import './ChangePassword.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Zustand
import useUserStore from '../../../store/userStore'

// React Hook Form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { changePasswordSchema } from '../../../validation/changePasswordSchema'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Template
import Private from '../../templates/private/Private'

type ChangePasswordFormValues = {
	password: string
	newPassword: string
	confirmNewPassword: string
}

const ChangePassword = () => {
	// Translation Hook
	const { t } = useTranslation()

	// State
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)

	// Zustand
	const userStore = useUserStore()

	// React Hook Form
	const formOptions = { mode: 'onChange', resolver: yupResolver(changePasswordSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid }
	} = useForm<ChangePasswordFormValues>(formOptions) // Form

	// Form submit
	const onSubmit = async (values: ChangePasswordFormValues) => {
		// Dispatch authentication
		const response = await userStore.changePassword(values)

		if (response) {
			reset()
			setShowSuccessMessage(true)
		}
	}

	if (showSuccessMessage) {
		return (
			<ContentWrapper className="ChangePassword">
				<H1 isCentered={true}>{t('changePassword:header')}</H1>

				<p className="ChangePassword_Desc">{t('changePassword:successMessage')}</p>

				<div className="ChangePassword_Success">
					<Button color="success" onClick={() => setShowSuccessMessage(false)} text={t('button:ok')} />
				</div>
			</ContentWrapper>
		)
	}

	return (
		<ContentWrapper className="ChangePassword">
			<H1 isCentered={true}>{t('changePassword:header')}</H1>

			<p className="ChangePassword_Desc">{t('changePassword:explanation')}</p>

			<Form className="ChangePassword_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<FormGroup>
					<Input
						icon="password"
						inputSize="lg"
						type="password"
						placeholder={t('form:password.placeholder')}
						label={t('form:password.label')}
						autoComplete="current-password"
						invalid={Boolean(errors?.password?.message)}
						invalidMessage={errors?.password?.message?.toString()}
						{...register('password')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						icon="password"
						inputSize="lg"
						type="password"
						placeholder={t('form:newPassword.placeholder')}
						label={t('form:newPassword.label')}
						autoComplete="new-password"
						invalid={Boolean(errors?.newPassword?.message)}
						invalidMessage={errors?.newPassword?.message?.toString()}
						{...register('newPassword')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						icon="password"
						inputSize="lg"
						type="password"
						placeholder={t('form:confirmNewPassword.placeholder')}
						label={t('form:confirmNewPassword.label')}
						autoComplete="new-password"
						invalid={Boolean(errors?.confirmNewPassword?.message)}
						invalidMessage={errors?.confirmNewPassword?.message?.toString()}
						{...register('confirmNewPassword')}
					/>
				</FormGroup>

				{userStore.error && (
					<FormGroup>
						<p className="ChangePassword_Error">{userStore.error}</p>
					</FormGroup>
				)}

				<FormGroup>
					<Button
						type="submit"
						disabled={userStore.loading || !isValid}
						loading={userStore.loading}
						loadingText={t('button:saving')}
						text={t('button:changePassword')}
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(ChangePassword)
