import './NotFound.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Template
import Public from '../../templates/public/Public'

// Atoms
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'

// Molecules
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'

export const NotFound = () => {
	// Translation Hook
	const { t } = useTranslation()

	return (
		<ContentWrapper className="NotFound">
			<H1 isCentered={true}>{t('notFound:header')}</H1>

			<H2>{t('notFound:title')}</H2>
			<p>{t('notFound:explanation')}</p>
			<ReactLink href="/">{t('notFound:link')}</ReactLink>
		</ContentWrapper>
	)
}

export default Public(NotFound)
