import i18next, * as i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Shared
import languageSettings from './ruya-shared/shared/config/languageSettings'
import resources from './ruya-shared/shared/locales/resources'
import { getCookie } from './ruya-shared/shared/utils/cookieHelper'

// Multi Line Translation Postprocessing
const multilineProcessor = (text: string) => {
	// If text has line breaks or enter
	const match = /\r|\n/.exec(text)
	if (match) {
		const items = text.split('\n')
		return (
			<>
				{items.map((item, i) => (
					<span key={i}>{item}</span>
				))}
			</>
		)
	}

	return text
}

async function initI18Next() {
	// Initialize i18n
	i18n.use(initReactI18next)
		.use({
			type: 'postProcessor',
			name: 'multilineProcessor',
			process: (value: string) => multilineProcessor(value)
		})
		.init({
			lng: getCookie(languageSettings.cookie.cookieName) || languageSettings.defaultLanguage, // Default Language
			fallbackLng: languageSettings.fallbackLanguage,
			defaultNS: 'common',
			resources,
			debug: process.env.NODE_ENV === 'development', // Debug only development mode
			interpolation: {
				escapeValue: false // not needed for react as it escapes by default
			},
			react: {
				// react i18next special options (optional)
				useSuspense: false
			},
			postProcess: ['multilineProcessor']
		})
}

// Set language and direction on language change
i18next.on('languageChanged', (lng: string) => {
	document.documentElement.lang = lng
	document.documentElement.dir = i18next.dir(lng)
})

// Initialize i18n
initI18Next()

export default i18n
