import { useEffect } from 'react'
import './DreamAdd.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'

// Store
import useJournalStore from '../../../store/journalStore'

// React Hook Form
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addDreamSchema } from '../../../validation/dreamSchema'
import { DevTool } from '@hookform/devtools'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const DreamAdd = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	// Load dream if id is provided
	useEffect(() => {
		if (!sleepSessionId) {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}, [sleepSessionId])

	// React Hook Form
	const initialDream = {
		sleepSessionId: sleepSessionId as string,
		dream: '',
		isLucidDream: false,
		isColor: true,
		isRecurring: false
	}

	// React Hook Form
	const formOptions = {
		defaultValues: initialDream,
		mode: 'onChange' as const,
		resolver: yupResolver(addDreamSchema)
	}
	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm(formOptions) // Form

	// Switch options
	const yesNoSwitchOptions = { on: { value: true, label: t('options:yes') }, off: { value: false, label: t('options:no') } }

	// Form submit
	const onSubmit = async (values: any) => {
		// Destruct values
		const { sleepSessionId, dream, isLucidDream, isColor, isRecurring } = values

		// Add dream
		const newDreamId = await journalStore.addDream(sleepSessionId, dream, isLucidDream, isColor, isRecurring)

		// Redirect to view dream
		if (newDreamId) {
			navigate(`${commonSettings.apps.web.paths.journal}/dream/${sleepSessionId}/${newDreamId}`)
		}
	}

	// Cancel dream handler
	const handleCancel = () => {
		// Show confirmation dialog if form is modified
		if (isDirty) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:cancelDreamEdit'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => navigate(commonSettings.apps.web.paths.journal)
					},
					{
						label: t('button:no')
					}
				]
			})
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}

	return (
		<ContentWrapper className="DreamAdd">
			<H1 isCentered={true}>{t('journal:newDreamHeader')}</H1>

			<Form className="DreamAdd_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<input type="hidden" {...register('sleepSessionId')} />

				<FormGroup>
					<TextArea
						label={t('form:dream.label')}
						rows={13}
						resize="vertical"
						placeholder={t('form:dream.placeholder')}
						invalid={Boolean(errors.dream?.message)}
						invalidMessage={errors.dream?.message}
						{...register('dream')}
					/>
				</FormGroup>

				<div className="DreamAdd_DreamOptionsGroup">
					<Controller
						control={control}
						name="isLucidDream"
						render={({ field: { onChange, onBlur, value, name, ref } }) => (
							<Switch
								values={yesNoSwitchOptions}
								ref={ref}
								name={name}
								label={t('form:isLucidDream.label')}
								showTooltip={true}
								tooltipText={t('form:isLucidDream.helper')}
								value={value}
								onChange={onChange}
							/>
						)}
					/>

					<Controller
						control={control}
						name="isColor"
						render={({ field: { onChange, onBlur, value, name, ref } }) => (
							<Switch
								values={yesNoSwitchOptions}
								ref={ref}
								name={name}
								label={t('form:isColor.label')}
								showTooltip={true}
								tooltipText={t('form:isColor.helper')}
								value={value}
								onChange={onChange}
							/>
						)}
					/>

					<Controller
						control={control}
						name="isRecurring"
						render={({ field: { onChange, onBlur, value, name, ref } }) => (
							<Switch
								values={yesNoSwitchOptions}
								ref={ref}
								name={name}
								label={t('form:isRecurring.label')}
								showTooltip={true}
								tooltipText={t('form:isRecurring.helper')}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</div>

				{journalStore.error && <p className="DreamAdd_Form_Error">{journalStore.error}</p>}

				<div className="DreamAdd_Form_Buttons">
					<Button type="button" color="light" text={t('button:cancel')} onClick={handleCancel} />
					<Button
						type="submit"
						disabled={isSubmitting || journalStore.loading}
						loading={isSubmitting || journalStore.loading}
						text={t('button:add')}
					/>
				</div>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(DreamAdd)
