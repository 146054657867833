import { useState } from 'react'
import './LanguageSelection.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Zustand
import useLanguageStore from '../../../store/languageStore'

// Router
import { useNavigate } from 'react-router-dom'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Fuzzy match
import Fuse from 'fuse.js'

// Molecules
import InputWithButton from '../inputWithButton/InputWithButton'

// Types
import type { ILanguage } from '../../../ruya-shared/shared/types'

// Config
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'


interface LanguageSelectionProps {
	shape?: '1x1' | '4x3'
	navigateTo?: string
}

const LanguageSelection = (props: LanguageSelectionProps) => {
	// Shape can be 1x1 or 4x3
	const { shape = '4x3', navigateTo = '' } = props

	// Translation Hook
	const { t } = useTranslation()

	// Router Hook
	const navigate = useNavigate()

	// Zustand
	const languageStore = useLanguageStore()

	// Local state
	const [searchInput, setSearchInput] = useState('')

	// Filter languages with fuzzyMatch utility function based on search input
	const fuseOptions = {
		// keys to search in
		keys: ['displayName', 'englishName', 'isoCode'],
		includeScore: true,
		threshold: 0.4 // Adjust this value based on your needs
	}
	const fuse = new Fuse(languageStore.activeLanguages, fuseOptions)
	const filteredLanguages = searchInput.trim() ? fuse.search(searchInput)?.map(result => result.item) : languageStore.activeLanguages

	// Set language
	const handleLanguageChange = (lang: ILanguage) => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:changeLanguage', { language: lang?.displayName }),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						// Update state
						await languageStore.setLanguage(lang.isoCode)

						// // Navigate if provided
						if (navigateTo) navigate(navigateTo)
					}
				},
				{
					label: t('button:cancel')
				}
			]
		})
	}

	return (
		<div className={`LanguageSelection LanguageSelection--${shape}`}>
			<div className="LanguageSelection">
				<InputWithButton
					id="searchInput"
					placeholder={t('form:filter.placeholder')}
					iconPosition="left"
					buttonIcon="filter_list"
					value={searchInput}
					onChange={e => setSearchInput(e.target.value)}
					type="text"
				/>
			</div>

			{filteredLanguages?.length > 0 && (
				<ol className="LanguageSelection_List">
					{filteredLanguages?.map((lang: ILanguage) => (
						<li key={lang?.isoCode}>
							<span onClick={() => handleLanguageChange(lang)}>
								{languageSettings.showCountryFlag && (
									<span
										className={`Flag Flag--${shape}`}
										style={{
											backgroundImage: `url(${commonSettings.apps.cdn.url}/assets/flags/${shape}/${lang.flag})`
										}}
									/>
								)}
								{lang.displayName}
							</span>
						</li>
					))}
				</ol>
			)}
		</div>
	)
}

export default LanguageSelection
