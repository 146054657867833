import * as Yup from 'yup'
import i18n from '../i18n'

import type { ISignUpSchema } from '../@types/validation'

// Regex
import { displayNameRegEx } from '../ruya-shared/shared/validation/regularExpressions'

// Sign up validation schema
export const signUpSchema: Yup.ObjectSchema<ISignUpSchema> = Yup.object({
	displayName: Yup.string()
		.required(i18n.t('form:displayName.required'))
		.min(3, i18n.t('form:displayName.min'))
		.max(15, i18n.t('form:displayName.max'))
		.matches(displayNameRegEx, i18n.t('form:displayName.format'))
		// .test('is-unique', i18n.t('form:displayName.unique'), function (value) {
		// 	// Don't run the test if the value is less than 3 characters
		// 	if (!value || value.length < 3) return true

		// 	return new Promise((resolve, reject) => {
		// 		// We're debouncing the Promise resolution itself
		// 		debounce(() => {
		// 			apiPublic
		// 				.get(`/auth/verify-display-name/${value}`)
		// 				.then(response => {
		// 					const isUnique = response.data as boolean

		// 					if (isUnique) {
		// 						return resolve(true)
		// 					}
		// 					return resolve(new Yup.ValidationError(i18n.t('form:displayName.unique'), value, 'displayName'))
		// 				})
		// 				.catch(error => resolve(new Yup.ValidationError(i18n.t('form:displayName.apiError'), value, 'displayName')))
		// 		}, 500)() // 1 second delay
		// 	})
		// })
	,
	email: Yup.string().required(i18n.t('form:email.required')).email(i18n.t('form:email.format')),
	password: Yup.string().required(i18n.t('form:password.required')).min(8, i18n.t('form:password.min')),
	confirmPassword: Yup.string().oneOf([Yup.ref('password')], i18n.t('form:confirmPassword.match')),
	captchaToken: Yup.string().required(i18n.t('form:captchaToken.required')),
	termsAndConditions: Yup.boolean().oneOf([true], i18n.t('form:confirmTermsAndConditions.required'))
})
