import { createRef, useEffect, useState } from 'react'
import './InterpreterSelection.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Store
import useJournalStore from '../../../store/journalStore'
import useInterpreterStore from '../../../store/interpreterStore'
import useLanguageStore from '../../../store/languageStore'

// Atoms
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Molecules
import Interpreter from '../../molecules/interpreter/Interpreter'

// Types
import type { IDream, IInterpretation, IInterpreter, ObjectId } from '../../../ruya-shared/shared/types'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Glide
import Glide from '@glidejs/glide'

// Utils
import coverFlowPlugin from '../../../ruya-shared/shared/utils/coverFlowPlugin'

const InterpreterSelection = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId, dreamId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const interpreterStore = useInterpreterStore()
	const languageStore = useLanguageStore()

	// Ref
	const interpreterSlideRef = createRef<HTMLDivElement>()

	// Local state
	const [interpretations, setInterpretations] = useState<IInterpretation[] | null>([])

	// Check parameters
	useEffect(() => {
		// Sleep session Id is required
		if (!sleepSessionId || !dreamId) navigate('/journal')
	}, [dreamId, sleepSessionId])

	// Load dream interpretations
	useEffect(() => {
		if (sleepSessionId) {
			journalStore.loadSleepSession(sleepSessionId)
		}
	}, [sleepSessionId])

	// Load dream interpretations
	useEffect(() => {
		if (interpreterSlideRef.current && interpreterStore.interpreterList.length > 0) {
			const glide = new Glide(interpreterSlideRef.current, {
				focusAt: 'center',
				startAt: 0,
				perView: 2,
				peek: 0,
				gap: 5,
				autoplay: 6000,
				direction: languageStore.selectedLanguage?.direction === 'rtl' ? 'rtl' : 'ltr',
				hoverpause: true,
				animationDuration: 600,
				rewindDuration: 0,
				touchRatio: 0.25,
				// @ts-ignore
				perTouch: 1,
				breakpoints: {
					992: {
						gap: 0,
						peek: 0,
						perView: 1
					}
				}
			})

			const coverFlowExtension = coverFlowPlugin('.Interpreter')

			glide.mount({ CoverFlow: coverFlowExtension })

			return () => {
				glide.destroy()
			}
		}
	}, [interpreterStore.interpreterList])

	// Load dream interpretations
	useEffect(() => {
		if (journalStore.currentSleepSession) {
			setInterpretations(
				journalStore.currentSleepSession?.dreams?.find((dream: IDream) => dream._id === dreamId)?.interpretations || []
			)
		}
	}, [journalStore.currentSleepSession])

	// Check if dream is interpreted by the interpreter
	const getInterpretation = (interpreterId: ObjectId) => {
		return interpretations?.find((interpretation: IInterpretation) => interpretation.interpreter?.id === interpreterId) || null
	}

	// Continue to dream-questionnaire
	const handleInterpret = (interpreterId: ObjectId) => {
		const tmpInterpretation = getInterpretation(interpreterId.toString())

		if (tmpInterpretation?.isDreamQACompleted) {
			navigate(
				`${commonSettings.apps.web.paths.journal}/dream/interpretation/${sleepSessionId}/${dreamId}/${tmpInterpretation._id?.toString()}`
			)
		} else {
			navigate(`${commonSettings.apps.web.paths.journal}/dream-questionnaire/${sleepSessionId}/${dreamId}/${interpreterId}`)
		}
	}

	return (
		<ContentWrapper className="InterpreterSelection">
			<HistoryBackLink href={`${commonSettings.apps.web.paths.journal}/dream/${sleepSessionId}/${dreamId}`} />

			<H1>{t('journal:interpretHeader')}</H1>
			<P className="InterpreterSelection_Explanation">{t('journal:interpretDescription')}</P>

			<div className="InterpreterSelection_InterpreterSlider">
				<div className="glide" data-component="hero" ref={interpreterSlideRef}>
					<div className="glide__arrows" data-glide-el="controls">
						<button type="button" className="glide__arrow glide__arrow--prev" data-ref="fadereveal[el]" data-glide-dir="<">
							<MaterialSymbol name="arrow_back_ios_new" />
						</button>

						<button type="button" className="glide__arrow glide__arrow--next" data-ref="fadereveal[el]" data-glide-dir=">">
							<MaterialSymbol name="arrow_forward_ios" />
						</button>
					</div>

					<div className="glide__bullets" data-glide-el="controls[nav]">
						{interpreterStore.interpreterList.map((interpreter: IInterpreter, i) => {
							return (
								<MaterialSymbol className="glide__bullet" key={interpreter._id?.toString()} name={interpreter?.icon} fill="1" data-glide-dir={`=${i}`} />
							)
						})}
					</div>

					<div data-glide-el="track" data-component="slidereveal" className="glide__track">
						<ul className="glide__slides">
							{interpreterStore.interpreterList.map((interpreter: IInterpreter) => {
								const isInterpreted = interpreter?._id
									? getInterpretation(interpreter?._id?.toString())?.isDreamQACompleted
									: false
								return (
									<li className="glide__slide" data-ref="slidereveal[el]" key={interpreter._id?.toString()}>
										<Interpreter
											interpreter={interpreter}
											handleInterpret={handleInterpret}
											isInterpreted={isInterpreted}
										/>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</div>

			{journalStore.error && (
				<FormGroup>
					<p className="InterpreterSelection_Error">{journalStore.error}</p>
				</FormGroup>
			)}
		</ContentWrapper>
	)
}

export default Private(InterpreterSelection)
