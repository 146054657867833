import type { ISubscriptionPackage } from '../types'

const subscriptionServices = {
	dreamJournal: {
		name: 'Dream journal',
		icon: 'book_5'
	},
	dreamInterpretation: {
		name: 'Dream interpretation',
		icon: 'psychology'
	},
	weeklyDreamReport: {
		name: 'Weekly dream report',
		icon: 'psychology_alt'
	}
}

export const subscriptionPackages: ISubscriptionPackage[] = [
	{
		id: '0',
		name: 'Friend of Ruya',
		isActive: true,
		isVisible: false,
		billingCycle: 'Lifetime',
		description: 'Free forever',
		price: 0,
		discount: 0,
		image: 'https://via.placeholder.com/150',
		services: [
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamJournal,
				description: 'Unlimited use of the dream journal.'
			},
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamInterpretation,
				description: 'Unlimited dream interpretations.'
			},
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.weeklyDreamReport,
				description: 'Receive weekly dream report based on weekly dream journal entries.'
			}
		]
	},
	{
		id: '1',
		name: 'Free',
		isActive: true,
		isVisible: true,
		billingCycle: 'Lifetime',
		description: 'Free with ads',
		price: 0,
		discount: 0,
		image: 'https://via.placeholder.com/150',
		services: [
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamJournal,
				description: 'Unlimited use of the dream journal.'
			},
			{
				limit: 1,
				...subscriptionServices.dreamInterpretation,
				description: '1 dream interpretations per month.'
			}
		]
	},
	{
		id: '2',
		name: 'Basic',
		isActive: true,
		isVisible: true,
		description: 'For beginners',
		billingCycle: 'Monthly',
		price: 4.99,
		discount: 0,
		image: 'https://via.placeholder.com/150',
		services: [
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamJournal,
				description: 'Unlimited use of the dream journal.'
			},
			{
				limit: 30,
				...subscriptionServices.dreamInterpretation,
				description: '30 dream interpretations per month.'
			}
		]
	},
	{
		id: '3',
		name: 'Premium',
		isActive: true,
		isVisible: true,
		description: 'Unlimited access to all features',
		billingCycle: 'Monthly',
		price: 9.99,
		discount: 0,
		image: 'https://via.placeholder.com/150',
		services: [
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamJournal,
				description: 'Unlimited use of the dream journal.'
			},
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamInterpretation,
				description: 'Unlimited dream interpretations.'
			},
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.weeklyDreamReport,
				description: 'Receive weekly dream report based on weekly dream journal entries.'
			}
		]
	},
	{
		id: '4',
		name: 'Basic',
		isActive: true,
		isVisible: true,
		description: 'For beginners',
		billingCycle: 'Yearly',
		price: 59.88,
		discount: 20,
		image: 'https://via.placeholder.com/150',
		services: [
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamJournal,
				description: 'Unlimited use of the dream journal.'
			},
			{
				limit: 30,
				...subscriptionServices.dreamInterpretation,
				description: '30 dream interpretations per month.'
			}
		]
	},
	{
		id: '5',
		name: 'Premium',
		isActive: true,
		isVisible: true,
		description: 'Unlimited access to all features',
		billingCycle: 'Yearly',
		price: 119.88,
		discount: 20,
		image: 'https://via.placeholder.com/150',
		services: [
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamJournal,
				description: 'Unlimited use of the dream journal.'
			},
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.dreamInterpretation,
				description: 'Unlimited dream interpretations.'
			},
			{
				limit: Number.POSITIVE_INFINITY,
				...subscriptionServices.weeklyDreamReport,
				description: 'Receive weekly dream report based on weekly dream journal entries.'
			}
		]
	}
]
