'use client'
import React, { useState, useEffect, useRef, useCallback, type ReactNode, type ReactElement } from 'react'
import 'tippy.js/dist/tippy.css'
import './Tooltip.scss'

// Tippy JS
import tippy, { type Props as TippyProps } from 'tippy.js'

// Helpers
import { renderToString } from 'react-dom/server'
import { generateRandomLetters } from '../../../utils/commonHelper'

interface TooltipProps {
	children: ReactElement
	content: ReactNode | string
	interactive?: boolean
	placement?: TippyProps['placement']
	trigger?: TippyProps['trigger']
	advancedOptions?: Partial<TippyProps>
	disabled?: boolean
}

const Tooltip = (props: TooltipProps) => {
	const { children, content, interactive, placement, trigger, advancedOptions, disabled } = props

	const computedContent = useCallback(() => {
		if (typeof content === 'string') {
			return content
		}
		if (React.isValidElement(content)) {
			return renderToString(content)
		}
		// Handle other cases or return a default/fallback
		return String(content)
	}, [content])

	const key = useState('tooltip_' + generateRandomLetters())[0]
	const instance = useRef<any | null>(null)

	useEffect(() => {
		const config = {
			allowHTML: true,
			content: computedContent,
			interactive,
			placement,
			trigger,
			...advancedOptions
		}

		const node = document.querySelector(`[data-tooltip="${key}"]`)

		if (node) {
			instance.current = tippy(node, config)
			instance.current.setProps(config)
			return () => instance.current.destroy()
		}

		return () => instance.current?.destroy()
	}, [key, content, computedContent, interactive, placement, trigger, advancedOptions])

	// Custom disabled state for tooltip
	if (disabled) return children

	// If child disabled wrap it with span to get events
	if (children?.props?.disabled === true) {
		const wrappedChild = <span style={{ display: 'grid' }}>{children}</span>
		return <>{React.cloneElement(wrappedChild, { 'data-tooltip': key })}</>
	}

	return <>{React.cloneElement(children, { 'data-tooltip': key })}</>
}

export default Tooltip
