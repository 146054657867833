import { useEffect } from 'react'
import './Timeline.scss'

import { DateTime } from 'luxon'

// Translation
import { useTranslation } from 'react-i18next'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import SleepQualityItem from '../../../ruya-shared/shared/ui/atoms/sleepQualityItem/SleepQualityItem'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import Label from '../../../ruya-shared/shared/ui/atoms/label/Label'
import ToggleButton from '../../../ruya-shared/shared/ui/atoms/toggleButton/ToggleButton'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import TimelineSeparator from '../../../ruya-shared/shared/ui/atoms/timelineSeparator/TimelineSeparator'

// Molecules
import DreamCard from '../../molecules/dreamCard/DreamCard'

// Router
import { useNavigate } from 'react-router-dom'

// Store
import useJournalStore from '../../../store/journalStore'
import useLanguageStore from '../../../store/languageStore'

// Types
import type { IDream, IJournal, ObjectId } from '../../../ruya-shared/shared/types'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Utils
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'


const Timeline = () => {
	// Store
	const journalStore = useJournalStore()
	const languageStore = useLanguageStore()

	// Navigate
	const navigate = useNavigate()

	// Translation Hook
	const { t } = useTranslation()

	useEffect(() => {
		journalStore.loadSleepSessions()
	}, [])

	const handleFavoriteToggle = (sleepSessionId: ObjectId, isFavorite: boolean) => {
		if (!journalStore.loadingFavorite) {
			journalStore.toggleFavorite(sleepSessionId, isFavorite)
		}
	}

	// TODO: Get the symbols and people from the user's profile by Id

	if (!journalStore.loading && !journalStore.error && journalStore.sleepSessionsData.list.length === 0) return null

	return (
		<div className="Timeline">
			<TimelineSeparator />
			{journalStore.sleepSessionsData.list.map((sleepSession: IJournal) => (
				<div key={sleepSession._id?.toString()}>
					<div className="SleepSession">
						<div className="SleepSession_Header">
							<Label className="SleepSession_Header_Title" size="sm">
								{t('journal:sessionTimelineHeader')}
							</Label>

							<div className="SleepSession_Header_Details">
								<SleepSessionDate sessionDate={sleepSession.sleepStart} />

								<div className="SleepSession_Header_Actions">
									<ToggleButton
										loading={journalStore.loadingFavorite === sleepSession._id?.toString()}
										icon="star"
										iconFill="0"
										checkedIcon="star"
										checkedIconFill="1"
										onChange={isChecked => sleepSession._id && handleFavoriteToggle(sleepSession._id, isChecked)}
										value={sleepSession.isFavorite}
									/>

									<Button
										color="light"
										size="sm"
										icon="edit"
										iconFill="1"
										onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/edit/${sleepSession._id}`)}
										text={t('button:edit')}
									/>
								</div>
							</div>
						</div>

						<div className="SleepSession_Details">
							<div>
								<MaterialSymbol name="clear_night" fill="1" />
								<span>{sleepSession.sleepStart && languageStore.selectedLanguage?.isoCode && formatDisplayDate(languageStore.selectedLanguage?.isoCode, sleepSession.sleepStart, DateTime.TIME_24_SIMPLE)}</span>
							</div>
							<div>
								<MaterialSymbol name="schedule" fill="1" />
								{t('form:sleepDuration:hours', { count: sleepSession.sleepDuration })}
							</div>
							<div>
								<SleepQualityItem sleepQuality={sleepSession.sleepQuality} />
							</div>
						</div>

						<div className="SleepSession_Dreams">
							{sleepSession?.dreams?.map((dream: IDream, i) => (
								<DreamCard key={dream._id?.toString()} dream={dream} sleepSession={sleepSession} />
							))}
						</div>

						<div className="SleepSession_Actions">
							<Button
								icon="add"
								size="sm"
								text={t('button:addDreamToThisSession')}
								onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/add/${sleepSession._id}`)}
							/>
						</div>
					</div>
					<TimelineSeparator />
				</div>
			))}

			{journalStore.loading && (
				<div className="Timeline_Loading">
					<Loading />
				</div>
			)}

			{/* {!journalStore.loading && !journalStore.error && journalStore.sleepSessionsData.list.length === 0 && (
				<div className="Timeline_NoRecords">{t('journal:noSleepSessions')}</div>
			)} */}

			{!journalStore.loading && journalStore.error && <div className="Timeline_Error">{journalStore.error}</div>}

			{!journalStore.loading &&
				!journalStore.error &&
				journalStore.sleepSessionsData.page < journalStore.sleepSessionsData.totalPages && (
					<div className="Timeline_LoadMore">
						<Button onClick={() => journalStore.loadMoreSleepSessions()} color="light" text={t('button:loadMore')} />
					</div>
				)}
		</div>
	)
}

// Sleep Session Date
interface ISleepSessionDateProps {
	sessionDate: string | Date | undefined
}
const SleepSessionDate = (props: ISleepSessionDateProps) => {
	const { sessionDate } = props

	const { i18n } = useTranslation()

	if (!sessionDate) return null

	const tmpSessionDate = new Date(sessionDate) // Sleep session date
	const currentDate = new Date() // Current date

	const shortMonthName = tmpSessionDate.toLocaleDateString(i18n.language, { month: 'short' }).toLocaleUpperCase(i18n.language)
	// const isCurrentMonth = tmpSessionDate.getMonth() === currentDate.getMonth() && tmpSessionDate.getFullYear() === currentDate.getFullYear()
	const isCurrentYear = tmpSessionDate.getFullYear() === currentDate.getFullYear()

	return (
		<div className="SleepSession_Header_Details_Date">
			{tmpSessionDate.getDate()} {shortMonthName} {!isCurrentYear && tmpSessionDate.getFullYear()}
		</div>
	)
}

export default Timeline
