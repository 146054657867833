import { useState } from 'react'
import './Interpreter.scss'

// Translation
import { useTranslation } from 'react-i18next'

// Types
import type { IInterpreter, ObjectId } from '../../../ruya-shared/shared/types'

// Atoms
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'

interface InterpreterProps {
	interpreter: IInterpreter
	handleInterpret: (id: ObjectId) => void
	isInterpreted?: boolean
}

const Interpreter = (props: InterpreterProps) => {
	const { interpreter, handleInterpret, isInterpreted = false } = props

	// Translation Hooks
	const { t } = useTranslation()

	// Local state
	const [showDetails, setShowDetails] = useState(false)

	// style={{ backgroundImage: `url(${interpreter.image})` }}

	return (
		<div className={`Interpreter${showDetails ? ' Interpreter--back' : ''}`} key={interpreter._id?.toString()} data-ref="hero[el]">
			<div className="Interpreter_Header">
				<MaterialSymbol className="Interpreter_Header_Icon" name={interpreter?.icon} fill="1" />
				<P size="lg">{interpreter?.name}</P>
			</div>

			<div className="Interpreter_Explanation">
				{!showDetails ? (
					<div className="Interpreter_Explanation_Description">
						<P size="md">{interpreter?.description}</P>
						<div className="Interpreter_Explanation_Description_Buttons">
							<Button
								color="light"
								type="button"
								icon="article_shortcut"
								text={t('button:focusAndBenefits')}
								onClick={() => setShowDetails(!showDetails)}
							/>
							{isInterpreted ? (
								<Button
									onClick={() => handleInterpret(interpreter._id as ObjectId)}
									text={t('button:viewInterpretation')}
								/>
							) : (
								<Button
									onClick={() => handleInterpret(interpreter._id as ObjectId)}
									text={t('button:continue')}
								/>
							)}
						</div>
					</div>
				) : (
					<div className="Interpreter_Explanation_Details">
						<P size="sm">
							<span>{t('journal:theoryFocus')}</span> {interpreter?.focus}
						</P>
						<P size="sm">
							<span>{t('journal:theoryBenefit')}</span> {interpreter?.benefit}
						</P>

						<Button
							color="light"
							type="button"
							icon="article_shortcut"
							text={t('button:back')}
							onClick={() => setShowDetails(!showDetails)}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default Interpreter
