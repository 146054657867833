import axios from 'axios'

// Zustand
import useLanguageStore from '../store/languageStore'

// Config
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

// Utils
import { getCookie } from '../ruya-shared/shared/utils/cookieHelper'

export const apiProtectedClient = axios.create({ baseURL: commonSettings.apps.api.url, headers: commonSettings.apps.api.headers })

// Request Interceptor
apiProtectedClient.interceptors.request.use(
	async axiosConfig => {
		// Get token from cookie
		const jwtToken = getCookie(commonSettings.apps.api.tokenName)

		// Add token to header for every call
		if (jwtToken) axiosConfig.headers.Authorization = `Bearer ${jwtToken}`

		// Zustand
		const { selectedLanguage } = useLanguageStore.getState()

		// Add current language to header for every call
		axiosConfig.headers['Accept-Language'] = selectedLanguage?.isoCode

		// Set content type
		if (!(axiosConfig.data instanceof FormData)) {
			axiosConfig.headers['Content-Type'] = 'application/json'
		} else {
			delete axiosConfig.headers['Content-Type'] // Let the browser set the content type
		}

		return axiosConfig
	},
	error => Promise.reject(error)
)

// Expose only required methods
const apiProtected = {
	post: apiProtectedClient.post,
	get: apiProtectedClient.get,
	head: apiProtectedClient.head,
	delete: apiProtectedClient.delete,
	put: apiProtectedClient.put,
	patch: apiProtectedClient.patch
}

export default apiProtected
