import { useEffect } from 'react'
import './DreamEdit.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import { Form, FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'

// Store
import useJournalStore from '../../../store/journalStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateDreamSchema } from '../../../validation/dreamSchema'
import { DevTool } from '@hookform/devtools'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Types
import type { IDream } from '../../../ruya-shared/shared/types'

const DreamEdit = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId, dreamId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	// Load sleep session
	useEffect(() => {
		if (sleepSessionId) {
			journalStore.loadSleepSession(sleepSessionId)
		}
	}, [sleepSessionId])

	// React Hook Form
	const initialDream = {
		title: '',
		dream: '',
		isLucidDream: false,
		isColor: true,
		isRecurring: false
	}

	// React Hook Form
	const formOptions = {
		defaultValues: initialDream,
		mode: 'onChange' as const,
		resolver: yupResolver(updateDreamSchema)
	}
	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm(formOptions) // Form

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (journalStore.currentSleepSession !== null && dreamId) {
			const requestedDream = journalStore.currentSleepSession?.dreams.find((dream: IDream) => dream._id === dreamId) || null

			if (requestedDream !== null) {
				reset({
					// Set form values
					title: requestedDream.title,
					dream: requestedDream.dream,
					isLucidDream: requestedDream.isLucidDream,
					isColor: requestedDream.isColor,
					isRecurring: requestedDream.isRecurring
				})
			}
		}
	}, [journalStore.currentSleepSession, dreamId])

	// Switch options
	const yesNoSwitchOptions = { on: { value: true, label: t('options:yes') }, off: { value: false, label: t('options:no') } }

	// Form submit
	const onSubmit = async (values: any) => {
		// Destruct values
		const { title, dream, isLucidDream, isColor, isRecurring } = values

		// Add dream
		const isUpdated = await journalStore.updateDream(sleepSessionId, dreamId, title, dream, isLucidDream, isColor, isRecurring)

		// Redirect to view dream
		if (isUpdated) {
			navigate(`${commonSettings.apps.web.paths.journal}/dream/${sleepSessionId}/${dreamId}`)
		}
	}

	// Cancel dream handler
	const handleCancel = () => {
		// Show confirmation dialog if form is modified
		if (isDirty) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:cancelDreamEdit'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => navigate(`${commonSettings.apps.web.paths.journal}/dream/${sleepSessionId}/${dreamId}`)
					},
					{
						label: t('button:no')
					}
				]
			})
		} else {
			navigate(`${commonSettings.apps.web.paths.journal}/dream/${sleepSessionId}/${dreamId}`)
		}
	}

	// Delete dream handler
	const handleDelete = () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteDream'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						const response = await journalStore.deleteDream(sleepSessionId, dreamId)

						if (response) {
							navigate(commonSettings.apps.web.paths.journal)
						}
					}
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	return (
		<ContentWrapper className="DreamEdit">
			<H1 isCentered={true}>{t('journal:editDreamHeader')}</H1>

			<Form className="DreamEdit_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<FormGroup>
					<Input
						label={t('form:dreamTitle.label')}
						placeholder={t('form:dreamTitle.placeholder')}
						invalid={Boolean(errors.title?.message)}
						invalidMessage={errors.title?.message}
						{...register('title')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						label={t('form:dream.label')}
						rows={13}
						resize="vertical"
						placeholder={t('form:dream.placeholder')}
						invalid={Boolean(errors.dream?.message)}
						invalidMessage={errors.dream?.message}
						{...register('dream')}
					/>
				</FormGroup>

				<div className="DreamEdit_DreamOptionsGroup">
					<Controller
						control={control}
						name="isLucidDream"
						render={({ field: { onChange, onBlur, value, name, ref } }) => (
							<Switch
								values={yesNoSwitchOptions}
								ref={ref}
								name={name}
								label={t('form:isLucidDream.label')}
								showTooltip={true}
								tooltipText={t('form:isLucidDream.helper')}
								value={value}
								onChange={onChange}
							/>
						)}
					/>

					<Controller
						control={control}
						name="isColor"
						render={({ field: { onChange, onBlur, value, name, ref } }) => (
							<Switch
								values={yesNoSwitchOptions}
								ref={ref}
								name={name}
								label={t('form:isColor.label')}
								showTooltip={true}
								tooltipText={t('form:isColor.helper')}
								value={value}
								onChange={onChange}
							/>
						)}
					/>

					<Controller
						control={control}
						name="isRecurring"
						render={({ field: { onChange, onBlur, value, name, ref } }) => (
							<Switch
								values={yesNoSwitchOptions}
								ref={ref}
								name={name}
								label={t('form:isRecurring.label')}
								showTooltip={true}
								tooltipText={t('form:isRecurring.helper')}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</div>

				{journalStore.error && <p className="DreamEdit_Form_Error">{journalStore.error}</p>}

				<div className="DreamEdit_Form_Buttons">
					<Button type="button" color="light" text={t('button:cancel')} onClick={handleCancel} />
					<Button
						type="submit"
						disabled={isSubmitting || journalStore.loading}
						loading={isSubmitting || journalStore.loading}
						text={t('button:saveChanges')}
					/>
				</div>

				<FormFieldSet legend={t('dialog:warningTitle')} className="DreamEdit_Delete">
					<P>{t('journal:deleteDreamWarning')}</P>
					<Button
						disabled={isSubmitting || journalStore.loading}
						loading={isSubmitting || journalStore.loading}
						text={t('button:delete')}
						icon="delete"
						onClick={handleDelete}
					/>
				</FormFieldSet>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(DreamEdit)
