import './H3.scss'

interface IH3Props {
	children: React.ReactNode | React.ReactNode[]
	isCentered?: boolean
	className?: string
}

const H3 = (props: IH3Props) => {
	const { children, isCentered, className } = props

	const classNames = 'H3' + `${isCentered ? ' H3--centered' : ''}` + `${className ? ' ' + className : ''}`

	return <h3 className={classNames}>{children}</h3>
}

export default H3
