// Try catch error handler
export const errorHandler = (error: any | unknown, reqOrI18n?: any): string => {
	if (error instanceof Error) {
		return error.message
	}
	if (typeof error === 'string') {
		return error
	}
	return reqOrI18n?.t('errors:unexpectedError') || 'Unexpected error'
}

export const apiErrorHandler = (error: any | unknown, reqOrI18n?: any): string => {
	let errorMsg: string

	// If axios detected an HTTP error status code
	if (error?.response) {
		// Specifically handling 403 error code
		if (error.response.status === 403) {
			errorMsg = error.response.data
		} else if (error.response.data && typeof error.response.data.message === 'string') {
			errorMsg = error.response.data.message
		} else {
			errorMsg = reqOrI18n?.t('errors:unexpectedError') || 'Unexpected error'
		}
	}
	// If request was made but no response received (e.g., network error)
	else if (error?.request) {
		errorMsg = reqOrI18n?.t('errors:noResponse') || 'No response received from server'
	}
	// If an error occurred in setting up the request
	else {
		errorMsg = error?.message || reqOrI18n?.t('errors:unexpectedError') || 'Unexpected error'
	}

	return errorMsg
}
