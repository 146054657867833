import './MaterialSymbol.scss'

interface MaterialSymbolProps {
	name: string
	fill?: '0' | '1'
	weight?: '100' | '200' | '300' | '400' | '500' | '600' | '700'
	grade?: '-25' | '0' | '200'
	opticalSize?: '20' | '24' | '40' | '48'
	className?: string
	[x: string]: any // For any additional props
}

const MaterialSymbol = (props: MaterialSymbolProps) => {
	// Destructure props
	const { name, fill = '0', weight = '400', grade = '0', size = '24', opticalSize = '20', className, ...rest } = props

	const customStyles = {
		fontVariationSettings: `"FILL" ${fill}, "wght" ${weight}, "GRAD" ${grade}, "opsz" ${opticalSize}`
	}

	const classNames = 'MaterialSymbol' + `${className ? ' ' + className : ''}`

	return (
		<span className={classNames} style={customStyles} {...rest}>
			{name}
		</span>
	)
}

export default MaterialSymbol
