import React from 'react'
import './StackButtons.scss'

interface IStackButtonProps {
	children: React.ReactNode | React.ReactNode[]
	direction?: 'horizontal' | 'vertical'
	gap?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
	radius?: 1 | 5 | 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50 | 60 | 70 | 80 | 90 | 100
}

const StackButtons = (props: IStackButtonProps) => {
	const { children, gap = 1, radius = 10, direction = 'horizontal' } = props

	// Get count of children
	const childrenCount = React.Children.count(children)

	const classNames =
		'StackButtons' +
		(direction === 'horizontal' ? ' StackButtons--horizontal' : ' StackButtons--vertical') +
		(childrenCount > 1 ? ' StackButtons--multiple' : '') +
		(gap ? ` StackButtons--gap-${gap}` : '') +
		(radius ? ` StackButtons--radius-${radius}` : '')

	return <div className={classNames}>{children}</div>
}

export default StackButtons
