import { useEffect, useMemo, useRef, useState } from 'react'
import './Logo.scss'

// Router
import { useNavigate } from 'react-router-dom'

// Localization
import { useTranslation } from 'react-i18next'

// Animation
import anime from 'animejs/lib/anime.es.js'

// Config
import { commonSettings } from '../../../config/commonSettings'

// Utils
import { getUniqueSlogans } from '../../../utils/commonHelper'

// Store
import useUserStore from '../../../../../store/userStore'

const Logo = () => {

	// Store
	const userStore = useUserStore()

	// Router Hook
	const navigate = useNavigate()

	// Translation Hook
	const { t } = useTranslation()

	const defaultSlogan = t('common:subtext')

	// Contains Unique Slogans
	const [slogans] = useState<string[]>(useMemo(() => getUniqueSlogans() as string[], []))
	const [slogan, setSlogan] = useState<string>(defaultSlogan)
	const sloganRef = useRef<HTMLDivElement>(null)
	const animationRef = useRef<anime.AnimeTimelineInstance | null>(null)

	const animateSlogan = (text: string) => {
		if (!sloganRef.current) return

		// Clear any existing animation
		animationRef.current?.pause()

		// Replace spaces with non-breaking space HTML entity
		const letters = text
			.split('')
			.map((char, index) => `<span class="Logo_Slogan_Letter" style="opacity: 0">${char === ' ' ? '&nbsp;' : char}</span>`)
			.join('')
		sloganRef.current.innerHTML = letters

		animationRef.current = anime
			.timeline({
				loop: false,
				autoplay: false,
				complete: () => {
					// Wait for 2 seconds before starting a new slogan animation
					setTimeout(() => setSlogan(getRandomSlogan()), 4000)
				}
			})
			.add({
				targets: sloganRef.current?.querySelectorAll('.Logo_Slogan_Letter'),
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				delay: anime.stagger(100)
			})

		animationRef.current.play()
	}

	const getRandomSlogan = (): string => {
		// Filter out the current slogan to ensure it's not selected again
		const newSlogansList = slogans.filter(s => s !== slogan)

		// Select a random slogan from the filtered list
		const newSlogan = newSlogansList[Math.floor(Math.random() * newSlogansList.length)]

		return newSlogan as string
	}

	useEffect(() => {
		// Start with a random slogan animation
		setSlogan(getRandomSlogan())
	}, [])

	useEffect(() => {
		// Animate the new slogan
		animateSlogan(slogan)
	}, [slogan])

	const handleMouseEnter = () => {
		// Pause the animation and show default slogan
		animationRef.current?.pause()

		// Set the slogan to the default without animating
		if (sloganRef.current) {
			sloganRef.current.innerHTML = defaultSlogan
		}
	}

	const handleMouseLeave = () => {
		// Resume animation with a random slogan
		setSlogan(getRandomSlogan())
	}

	const handleHomePage = () => {
		if (userStore.user) {
			navigate(commonSettings.apps.web.paths.journal)
		} else {
			navigate(commonSettings.apps.web.paths.login)
		}
	}

	return (
		<div className="Logo" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<img src="https://cdn.ruya.co/cdn/assets/logo.svg" alt="Ruya Web App" onClick={handleHomePage} />
			<div className="Logo_Slogan" ref={sloganRef} dir="auto" />
		</div>
	)
}

export default Logo
