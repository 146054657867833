import { useEffect, useRef, useState } from 'react'
import './ForgotPassword.scss'

// Cloudflare Turnstile
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile'

// Router
import { useNavigate } from 'react-router-dom'

// Localization
import { useTranslation } from 'react-i18next'

// Zustand
import useUserStore from '../../../store/userStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { forgotPasswordSchema } from '../../../validation/forgotPasswordSchema'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Template
import Public from '../../templates/public/Public'

// Utils
import { getSupportedTurnstileLanguage } from '../../../ruya-shared/shared/utils/commonHelper'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

type ForgotPasswordFormValues = {
	email: string
	captchaToken: string
}

const ForgotPassword = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Turnstile
	const captchaTokenRef = useRef<TurnstileInstance | null>(null)

	// State
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)

	// Zustand
	const userStore = useUserStore()
	const languageStore = useLanguageStore()

	// Router
	const navigate = useNavigate()

	// Check authentication
	useEffect(() => {
		if (userStore.user) {
			// Redirect to dashboard
			navigate(commonSettings.apps.web.paths.dashboard)
		} else {
			userStore.authenticateUserWithToken()
		}
	}, [userStore.user])

	// React Hook Form
	const formOptions = { mode: 'onChange', resolver: yupResolver(forgotPasswordSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid }
	} = useForm<ForgotPasswordFormValues>(formOptions) // Form

	// Form submit
	const onSubmit = async (values: ForgotPasswordFormValues) => {
		// Dispatch authentication
		const result = await userStore.forgotPassword(values)

		// Reset captcha
		captchaTokenRef.current?.reset()

		// Show success message
		if (result) {
			reset()

			setShowSuccessMessage(true)
		}
	}

	if (showSuccessMessage) {
		return (
			<ContentWrapper className="ForgotPassword">
				<H1 isCentered={true}>{t('forgotPassword:header')}</H1>

				<p className="ForgotPassword_Desc">{t('forgotPassword:successMessage')}</p>

				<div className="ForgotPassword_Success">
					<Button onClick={() => navigate(commonSettings.apps.web.paths.login)} text={t('button:ok')} />
				</div>
			</ContentWrapper>
		)
	}

	if (!commonSettings.services.isForgotPasswordEnabled)
		return (
			<ContentWrapper className="ForgotPassword">
				<H1 isCentered={true}>{t('forgotPassword:header')}</H1>
				<InfoBox type="error" icon="error" text={t('forgotPassword:serviceDisabled')} />
			</ContentWrapper>
		)

	return (
		<ContentWrapper className="ForgotPassword">
			<H1 isCentered={true}>{t('forgotPassword:header')}</H1>

			<p className="ForgotPassword_Desc">{t('forgotPassword:explanation')}</p>

			<Form className="ForgotPassword_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<FormGroup>
					<Input
						icon="email"
						inputSize="lg"
						type="text"
						placeholder={t('form:email.placeholder')}
						label={t('form:email.label')}
						autoComplete="email"
						invalid={Boolean(errors?.email?.message)}
						invalidMessage={errors?.email?.message}
						{...register('email')}
					/>
				</FormGroup>

				{userStore.error && (
					<FormGroup>
						<p className="ForgotPassword_Error">{userStore.error}</p>
					</FormGroup>
				)}

				<FormGroup>
					<Controller
						control={control}
						name="captchaToken"
						render={({ field }) => {
							const { onChange } = field
							return (
								<Turnstile
									ref={captchaTokenRef}
									siteKey={commonSettings.cloudFlare.turnstile.turnstileSiteKey}
									scriptOptions={{ async: true, defer: true }}
									options={{
										tabIndex: 3,
										language: getSupportedTurnstileLanguage(languageStore.selectedLanguage?.isoCode)
									}}
									onError={() => captchaTokenRef.current?.reset()}
									onSuccess={token => onChange(token)}
								/>
							)
						}}
					/>
				</FormGroup>

				<FormGroup>
					<Button
						type="submit"
						block={true}
						disabled={userStore.loading || !isValid}
						loading={userStore.loading}
						text={t('button:emailResetLink')}
						loadingText={t('button:loading')}
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Public(ForgotPassword)
