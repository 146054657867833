import './TypingDots.scss'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

const TypingDots = () => {
	return (
		<div className="TypingDots">
			<MaterialSymbol name="circle" fill="1" />
			<MaterialSymbol name="circle" fill="1" />
			<MaterialSymbol name="circle" fill="1" />
		</div>
	)
}

export default TypingDots
