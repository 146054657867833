import { useEffect } from 'react'

// Router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Utils
import ScrollToTop from '../ruya-shared/shared/utils/ScrollTop'

// Private Route
import Private from './Private'

// Public pages
import Login from '../ui/pages/login/Login'
import PublicLanguages from '../ui/pages/publicLanguages/PublicLanguages'
import AccountStatus from '../ui/pages/accountStatus/AccountStatus'
import SignUp from '../ui/pages/signUp/SignUp'
import ForgotPassword from '../ui/pages/forgotPassword/ForgotPassword'
import ResetPassword from '../ui/pages/resetPassword/ResetPassword'
import Activation from '../ui/pages/activation/Activation'
import NotFound from '../ui/pages/notFound/NotFound'

// Private pages
import Logout from '../ui/pages/logout/Logout'
import Profile from '../ui/pages/profile/Profile'
import Languages from '../ui/pages/languages/Languages'
import ChangePassword from '../ui/pages/changePassword/ChangePassword'
import Subscriptions from '../ui/pages/subscriptions/Subscriptions'

import Journal from '../ui/pages/journal/Journal'

import SleepSessionsAdd from '../ui/pages/sleepSessionsAdd/SleepSessionsAdd'
import SleepSessionsEdit from '../ui/pages/sleepSessionsEdit/SleepSessionsEdit'

import DreamAdd from '../ui/pages/dreamAdd/DreamAdd'
import DreamView from '../ui/pages/dreamView/DreamView'
import DreamEdit from '../ui/pages/dreamEdit/DreamEdit'

import InterpreterSelection from '../ui/pages/interpreterSelection/InterpreterSelection'
import DreamInterpretation from '../ui/pages/dreamInterpretation/DreamInterpretation'
import DreamViewInterpretation from '../ui/pages/dreamViewInterpretation/DreamViewInterpretation'

// Store
import useMainStore from '../store/mainStore'

// Config
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

// Router config
const AppRouter = () => {
	// Load navigation data
	const mainStore = useMainStore()

	useEffect(() => {
		mainStore.onAppLoad()
	}, [])

	return (
		<Router>
			<ScrollToTop />
			<Routes>
				{/* Public routes */}
				<Route path={commonSettings.apps.web.paths.home} element={<Login />} />
				<Route path={commonSettings.apps.web.paths.login} element={<Login />} />
				<Route path={commonSettings.apps.web.paths.signUp} element={<SignUp />} />
				<Route path={commonSettings.apps.web.paths.language} element={<PublicLanguages />} />
				<Route path={commonSettings.apps.web.paths.accountStatus} element={<AccountStatus />} />
				<Route path={commonSettings.apps.web.paths.forgotPassword} element={<ForgotPassword />} />
				<Route path={`${commonSettings.apps.web.paths.resetPassword}/:token`} element={<ResetPassword />} />
				<Route path={`${commonSettings.apps.web.paths.activation}/:token`} element={<Activation />} />
				<Route path={commonSettings.apps.web.paths.logout} element={<Logout />} />

				{/* Private routes */}
				<Route path={`${commonSettings.apps.web.paths.profile}`} element={<Private Component={Profile} />} />
				<Route path={`${commonSettings.apps.web.paths.loggedInLanguage}`} element={<Private Component={Languages} />} />
				<Route path={`${commonSettings.apps.web.paths.changePassword}`} element={<Private Component={ChangePassword} />} />
				<Route path={`${commonSettings.apps.web.paths.subscription}`} element={<Private Component={Subscriptions} />} />

				<Route path={commonSettings.apps.web.paths.journal} element={<Private Component={Journal} />} />

				<Route path={`${commonSettings.apps.web.paths.journal}/session/add`} element={<Private Component={SleepSessionsAdd} />} />
				<Route path={`${commonSettings.apps.web.paths.journal}/edit/:sleepSessionId`} element={<Private Component={SleepSessionsEdit} />} />

				<Route path={`${commonSettings.apps.web.paths.journal}/dream/add/:sleepSessionId`} element={<Private Component={DreamAdd} />} />
				<Route path={`${commonSettings.apps.web.paths.journal}/dream/:sleepSessionId/:dreamId`} element={<Private Component={DreamView} />} />
				<Route path={`${commonSettings.apps.web.paths.journal}/dream/edit/:sleepSessionId/:dreamId`} element={<Private Component={DreamEdit} />} />
				<Route path={`${commonSettings.apps.web.paths.journal}/dream/interpretation/:sleepSessionId/:dreamId/:interpretationId`} element={<Private Component={DreamViewInterpretation} />} />

				<Route path={`${commonSettings.apps.web.paths.journal}/select-interpreter/:sleepSessionId/:dreamId`} element={<Private Component={InterpreterSelection} />} />
				<Route path={`${commonSettings.apps.web.paths.journal}/dream-questionnaire/:sleepSessionId/:dreamId/:interpreterId`} element={<Private Component={DreamInterpretation} />} />

				{/* Not Found */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	)
}

export default AppRouter
